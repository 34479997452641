@import 'fonts/fonts';
@import 'variables';
@import 'mixins';
@import 'reset';
@import 'grids/grids';
@import 'placeholders';
@import 'colors';
@import 'typography';
@import 'animations';

html {
  height: 100%;
  font-family: $font;
  font-size: 10px;
}

body {
  height: 100%;
  min-width: $sm-start;
  background: $bg-primary;
  color: $color-primary;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: -.2px;

  &.modal__open {
    overflow: hidden;
  }
}

#root {
  height: 100%;
  position: relative;
}

.main-container {
  @include row();
  @include row-flex-column();
  @include between();
  position: relative;
  min-height: 100%;

  &:not(.-index) {
    padding-top: 5.4rem;

    @include over-md() {
      padding-top: 9.5rem;
    }

    @include over-lg() {
      padding-bottom: 3rem;
    }
  }

  &:not(._no-footer) {

    @include over-lg() {
      flex-wrap: nowrap;
      flex-direction: row;
      padding-left: 16.5rem;
    }

    @include over-xxlg() {
      padding-left: 0;
    }
  }

  .container {
    @include row();
    @include row-flex-column();
    @include centerBlock($width: 100%, $maxWidth: $md-max);
    min-width: $sm-start;
    padding-right: 2 * $gutter;
    padding-left: 2 * $gutter;
    flex-grow: 1;
    position: relative;

    @include under-lg() {
      padding-bottom: 50px;
    }
    @include under-md() {
      padding-bottom: 70px;
    }

    @include over-md() {
      margin: 0;
      padding-left: 3 * $gutter;
      padding-right: 3 * $gutter;
      max-width: none;
    }

    @include over-xxlg() {
      margin: 0 auto;
      padding: 0 3.2rem;
      max-width: 1440px;
    }

    &-inner {
      @include row();
      @include row-flex-column();
      width: 100%;
      flex-grow: 1;
    }
  }

  &:not(._no-footer) {

    .container {

      @include over-xxlg() {
        padding-left: calc(920px - 50vw);
      }

      @media (min-width: 1840px) {
        padding-left: calc(960px - 50vw);
      }
    }
  }

  &.-index {

    .container-inner {
      padding-top: 5.4rem;

      @include over-md() {
        padding-top: 9.5rem;
      }

      &._landing {
        background: $bg-secondary url('/img/bg-main.jpg') no-repeat center top;
        background-size: cover;
      }
    }
  }
}

.centered-block {
  @include centerBlock($width: 100%, $maxWidth: $xxlg+px);
  min-width: $sm-start;
  padding-right: 2 * $gutter;
  padding-left: 2 * $gutter;

  @include over-md() {
    padding-right: 3.2rem;
    padding-left: 3.2rem;
  }
}

.row {
  &.-width {
    @extend %row-width;
  }
  &.-height {
    @extend %row-height;
  }
}

.container {

  &__buttons {
    margin-top: auto;
    padding-top: 2rem;
  }
}

a {
  color: $color-link;
  text-decoration: none;

  &:hover {
    color: $color-link-hover;
  }

  &.-inverse-no-hover {
    color: $color-inverse;
  }

  &.-inverse {
    color: $color-inverse;

    &:hover {
      color: $color-link-hover;
    }
  }
}

sup,
sub {
  font-size: .5em;
}

button {
  &:not(:disabled) {
    cursor: pointer;
  }
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 700;
}

.under-md {

  @include over-md() {
    display: none !important;
  }
}

.over-md {

  @include under-md() {
    display: none !important;
  }
}

//LAYOUT
.fg-1 {
  flex-grow: 1;
}
