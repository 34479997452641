@import "src/styles/scss/basic/grids/variables";
@import "src/styles/scss/basic/variables";
@import "src/styles/scss/basic/mixins";

.cart {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.6rem 0 0;
    max-width: 100%;

	@include over-lg() {
		align-items: stretch;
		padding: 0;
	}
}

.orderDetails {
	align-self: stretch;
	display: flex;
	align-items: center;
	padding: 0 3rem;
	min-height: 6.6rem;
	border-top: .1rem solid $ctrl-border;
	border-bottom: .1rem solid $ctrl-border;
	font-weight: 300;
	font-size: 2.5rem;
	line-height: 30/25+em;
	color: $btn-color-secondary;

	@include under-sm() {
		display: none;
	}

	&Title {
		margin-right: auto;
		color: #000;

		@include under-sm() {
			display: none;
		}
	}

	b {
		font-weight: 600;
		color: #000;
	}
}

.up {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	width: 100%;

	@include over-sm() {
		flex-direction: row;
		align-items: stretch;
		margin-bottom: 3.2rem;
		padding: 2.2rem 0 6.2rem;
		border-bottom: .1rem solid $ctrl-border;
	}

	&Right {
		position: relative;
		align-self: stretch;

		@include over-sm() {
			order: 1;
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			margin-top: 3.2rem;
			margin-bottom: -3rem;
		}

		&Text {
			align-self: stretch;
			display: flex;
			flex-direction: column;
			align-items: stretch;

			@include over-sm() {
				align-self: center;
				align-items: flex-start;
			}
		}
	}
}

.congratulations {
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: height 2.5s, opacity 2s;

	@include over-sm() {
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0 2rem 0 5.5rem;
	}

	&:global {

		&._hidden {
			display: none;
			opacity: 0;
			pointer-events: none;
			position: absolute;
		}
	}
}

.info {
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	width: 100%;
	font-weight: 300;
	font-size: 1.8rem;
	line-height: 21/16+em;
	color: $color-content;
	text-align: center;

	@include over-sm() {
		padding: 0 2rem 0 5.5rem;
		font-size: 2rem;
		line-height: 3/2+em;
		text-align: justify;
	}

	&:global {

		&._show {
			opacity: 1;
			transition: opacity 2s;
		}

		&._static {
			position: static;
			opacity: 1;
			pointer-events: auto;
		}
	}

	&Black {
		font-weight: 400;
		color: #000;
	}

	&UnderLg {
		margin-top: 1.4rem;

		@include over-lg() {
			display: none;
		}
	}
}

.params {

	&Table {

		&:global {

			&.table {
				width: auto;

				@include under-sm() {
					margin: auto;
				}

				td {

					&:first-child {
						text-align: left;
					}
				}
			}
		}
	}
}

.icon {
	margin-bottom: .6rem;

	@include over-lg() {
		margin: 0 2rem 0 0;
	}
}

.title {
	margin-bottom: .5rem;
	max-width: 100%;
	text-align: center;

	&:global {

		&.text-1 {

			@include over-lg() {
				margin-bottom: .8rem;
				opacity: .5;
				font-weight: 600;
				font-size: 3.4rem;
				line-height: 41/34+em;
			}
		}
	}
}

.subtitle {
	margin-bottom: 1.2rem;
	max-width: 100%;
	text-align: center;

	&:global {

		&.text-1 {
			color: $color-content;

			@include over-lg() {
				margin: 0;
				opacity: .5;
				font-size: 2.5rem;
				line-height: 30/25+em;
				text-align: left;
			}
		}

		&._hide-over-lg {

			@include over-lg() {
				display: none;
			}
		}
	}

	b {
		font-weight: 600;
		color: #000;
	}
}

.slider {

	cursor: pointer;

	&:global {

		&.slider {
			margin-top: 1.6rem;
			margin-bottom: 5.5rem;

			@include over-sm() {
				flex: 0 0 45rem;
				margin-bottom: 0;
			}
			
			@include over-md() {
				margin: 0;
				min-width: 52.2rem;
				min-height: 31.4rem;
				flex: 0 0 52.2rem;
			}

			.slider {

				&-images {

					@include over-lg() {

					}
				}
			}
		}
	}
}

.removeBtn.removeBtn {
	margin: 2rem 0;

	@media only screen and (min-width: 1024px ) {
		display: inline-flex;
    vertical-align: top;
    justify-content: center;
    align-items: center;
    width: auto;
    min-width: 40rem;
    height: 6.6rem;
    border-radius: 3.3rem;
    font-size: 2.4rem;
	}

	@include over-sm() {
		margin: 0;
		align-self: flex-end;
		width: 80%;
	}
}

.ring__right {
	width: 20rem;
}

.rings {
    align-self: stretch;

	@include over-lg() {
		padding: 0 3rem;
	}

	&Subtitle {
		margin-bottom: 1.7rem;
		font-weight: 700;
		font-size: 2rem;
		line-height: 24/20+em;
	}

	&Select {

		@include over-lg() {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}

.title2 {

	&:global {

		&.text-1 {
			margin-bottom: 1.4rem;

			@include over-lg() {
				font-size: 2.4rem;
				line-height: 29/24+em;
			}
		}
	}

	:global {

		._carat {
			color: $color-carat;
		}

		._secondary {
			color: $color-secondary;
		}
	}
}

.checkboxes {
	margin-top: 1.9rem;
	margin-bottom: 3.5rem;
	width: 100%;

	@include over-lg() {
		display: flex;
		flex-direction: row;
	}

	:global {

		.checkbox {

			&:not(:last-child) {
				margin-bottom: 2.6rem;
			}
		}
	}
}

.checkbox {

	@include over-lg() {

		&:not(:last-child) {
			margin: 0 2rem 0 0 !important;
		}

		label {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;

			i {
				position: absolute !important;
				bottom: 3.2rem;
				left: 50%;
				transform: translateX(-50%);
			}

			span {
				order: -1;
				margin: 0 !important;
			}
		}
	}
}

.ring {
	display: flex;
	align-items: center;

	@include over-lg() {
		flex-direction: column;
	}

	&Image {
		display: flex;
		align-items: center;
		min-height: 6.1rem;
		max-width: 10.2rem;
		line-height: 0;

		img {
			max-width: 100%;
		}

		@include over-lg() {
			max-width: 16.1rem;
			min-height: 8.5rem;
		}
	}

	&Title {

		@include over-lg() {
			margin-top: 4rem;
			font-size: 2rem;
			line-height: 24/20+em;
			color: $color-content;
			text-align: center;
		}
	}
}

.select {

	&:global {

		&.select {
			margin-top: 1.9rem;

			@include over-lg() {
				width: 100%;
			}

			:local {

				.select {

					&Placeholder {
						font-weight: 300;
						font-size: 1.4rem;
						line-height: 17/14+em;
						color: $color-content;

						b {
							font-weight: 700;
						}

						@include over-lg() {
							font-size: 2.4rem;
							line-height: 29/24+em;
						}
					}
				}
			}
		}
	}

	&Wrapper {
		margin-bottom: 2rem;

		@include over-lg() {
			flex: 0 0 34rem;
			min-width: 34rem;
		}
	}
}

.upgrade {
	&__removeTopDashedLine {
		border-top: none !important;
		margin-bottom: 30px !important;
		border-bottom: .1rem solid $ctrl-border !important;
		display: flex !important;
		flex-direction: row !important;
		padding-bottom: 2rem;

	}
	
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 -2rem 2.4rem;
	padding: 1.8rem 2rem 0;
	border-top: .2rem dashed rgba($ctrl-border,.35);

	@include over-lg() {
		flex-direction: row;
		margin: 0;
		padding: 3.2rem 3rem;
		border-bottom: .1rem solid $ctrl-border;
	}

	&Left {

		@include over-lg() {
			flex: 0 1 56rem;
		}
	}

	&Title {
		margin-bottom: 1.2rem;
		width: 100%;
		font-weight: 700;
		font-size: 1.8rem;
		line-height: 22/18+em;

		@include over-lg() {
			margin-bottom: .8rem;
			font-size: 2rem;
			line-height: 24/20+em;
		}
	}

	&P {
		margin-bottom: 2.5rem;
		font-weight: 300;
		font-size: 1.8rem;
		line-height: 22/18+em;
		color: $color-content;

		@include over-lg() {
			margin: 0;
			font-size: 2rem;
			line-height: 3/2+em;
		}
	}

	&Btn {
		margin-left: auto;
	}
}

.total {

	@include over-lg() {
		margin: 0 0 5.8rem;
		padding: 0 3rem 4.3rem;
		border-bottom: .1rem solid $ctrl-border;
	}
}

.discount {
    position: relative;
    align-self: flex-start;
    display: flex;
    align-items: center;
    margin: 3rem 0;
    max-width: 100%;
    border-bottom: .1rem solid $ctrl-compare-color;

	@include over-lg() {
        margin: 3rem;
	}

	&Input {

        &:global(.input) {
            flex: 1 1 35rem;
            margin-bottom: -.1rem;
            width: 35rem;
            min-width: 0;
        }
	}

	&Submit {

        &:global(.btn) {
            flex: 0 0 auto;
            margin-bottom: -.1rem;
        }
	}

    &Message {
        position: absolute;
        top: 100%;
        display: flex;
        align-items: center;
        margin-top: .5rem;

        @include under-lg() {
            font-size: 1.4rem !important;
        }

        &._success {
            color: $color-accent;
        }

        &._error {
            color: $color-accent-secondary;
        }

        &Icon {
            position: relative;
            margin-right: .4rem;
            min-width: 2rem;
            height: 2rem;
            border-radius: 50%;
            border: .2rem solid;
        }

        &._success & {

            &Icon {
                border-color: $color-accent;

                &:before {
                    content: '';
                    position: absolute;
                    top: .1rem;
                    left: .5rem;
                    width: .6rem;
                    height: 1.2rem;
                    border-bottom: .2rem solid $color-accent;
                    border-right: .2rem solid $color-accent;
                    transform: rotate(45deg);
                }
            }
        }

        &._error & {

            &Icon {
                border-color: $color-accent-secondary;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: .2rem;
                    left: .7rem;
                    width: .2rem;
                    height: 1.2rem;
                    background: $color-accent-secondary;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }
        }
    }
}

.table {
	margin-bottom: 3.3rem;
	table-layout: fixed;
	border-collapse: collapse;
	width: 100%;
	padding: 0;
	border: none;
	border-bottom: .1rem solid $ctrl-color;

	@include over-lg() {
		border-bottom: none;
	}
	@include under-sm() {
		border-bottom: none;
	}

	tr {

		&:not(:last-child) {

			td {
				@include over-lg() {
					border-bottom: none;
				}
			}
		}
	}

	td {
		height: 4rem;
		font-weight: 400;
		font-size: 1.8rem;
		line-height: 22/18+em;
		color: #000;
		letter-spacing: -.02em;

		@include over-lg() {
			padding: .6rem 0;
		}

		&:not(:last-child) {
			padding-right: 1rem;
		}

		&:first-child {
			font-weight: 300;
			color: $color-content;

			@include over-lg() {
				font-size: 3rem;
				line-height: 37/30+em;
			}
		}

		&:nth-child(2) {
			font-weight: 600;
			color: $color-link-hover;
			text-align: right;

			@include over-lg() {
				font-weight: 700;
				font-size: 4.2rem;
				line-height: 52/42+em;
			}
		}
	}
}

.footer {
    align-self: stretch;

		@include over-md() {
			padding-bottom: 30px;
		}

    @include over-lg() {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
}

.checkout {

    &:global(.btn) {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            margin-right: .4rem;
            width: 2rem;
            height: 2rem;

            path {
                fill: #fff;
            }
        }
    }
}

.payments {
	width: 100%;
	margin: 2.5rem 0 0;
	line-height: 0;
	text-align: center;

	img {
		max-width: 100%;
	}

	@include over-lg() {
		margin: 0 auto 0 0;
		padding: 0 3rem;
		width: auto;
	}
}

.cart-button-wrapper {
	position: absolute;
	left: 40%;
}
.loader__center {
	left: 40%;
	top:40%
}

.__red {
	color: red;
}

.btn__cart_container {
	width: 100%;
	margin-bottom: 20px;
	@include over-lg() {
		width: 50%;
	}

}