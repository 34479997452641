.by-price {
  padding-top: $gutter;
  padding-bottom: $gutter;

  @include over-md() {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin: 0 -1rem;
    padding: 0 0 13.9rem;
  }

  &_item {
    display: block;
    padding: (3 * $gutter) (1.5 * $gutter) (1.5 * $gutter);
    background: $color-accent;
    border-radius: 4px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    text-align: center;
    position: relative;
    @include slideAnimationDefaults();

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        animation: slide-from-left-animation .75s (.75 * ($i - 1))+s forwards;
      }
    }

    @include over-md() {
      flex: 1 1 calc(33.333% - 2rem);
      min-width: calc(33.333% - 2rem);
      max-width: calc(33.333% - 2rem);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 1rem;
      padding: 3.9rem 2rem 3.2rem;
      min-height: 18.2rem;
      border-radius: 1.2rem;
    }

    &:before {
      content: '';
      display: block;
      width: 30px;
      height: 3px;
      background: $color-inverse;
      border-radius: 2px;
      position: absolute;
      top: 18px;
      left: calc(50% - 15px);

      @include over-md() {
        position: static;
        margin-bottom: 1.4rem;
        width: 4.6rem;
        height: .5rem;
        border-radius: .3rem;
      }
    }

    &:not(:first-child) {
      margin-top: $gutter;

      @include over-md() {
        margin-top: 0;
      }
    }

    &:hover {
      &:before {
        background: $color-link;
      }
    }
  }

  &_range {
    padding: ($gutter / 2);
    font-size: 2.2rem;
    font-weight: 600;

    @include over-md() {
      font-size: 3rem;
    }
  }

  &_descr {
    margin: 2rem 0;
    font-size: 1.4rem;
    letter-spacing: -.15px;

    @include over-md() {
      margin: 0;
      max-width: 22rem;
      font-size: 1.8rem;
    }
  }
}
