.shop-by {

  &-item {
    flex-basis: 100%;
    max-width: 100%;
    padding: 1rem;
    @include slideAnimationDefaults();

    @include over-sm() {
      flex-basis: 33.333%;
      max-width: 33.333%;
    }

    &:hover {
      .shop-by-ctrl {
        color: $color-link-hover;
      }
    }

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        animation: slide-from-left-animation .75s (.75 * ($i - 1))+s forwards;
      }
    }
  }

  &-link {
    display: block;
    border-radius: .6rem;
    box-shadow: 0 .8rem 1.1rem 0 rgba(0, 0, 0, .1);
    color: $color-link;
    text-decoration: none;

    @include over-md() {
      display: block;
    }
  }

  &-pic {
    height: 12.4rem;
    overflow: hidden;
    border-radius: 5px 5px 0 0;

    @include over-md() {
      height: 16.8rem;
      border-radius: .6rem .6rem 0 0;
    }
  }

  &-img {
    display: block;
    margin: 0 auto;

    @include over-md() {
      height: 100%;
      width: 100%;
    }

    @include under-sm() {
      max-width: 335px;
    }

    @media only screen and (max-width: 62em) {
      max-width: 100%;
    }
  }

  &-descr {
    @include row();
    @include middle();
    @include flexWrap(nowrap);
    padding: ($gutter / 2) $gutter;
    border-radius: 0 0 5px 5px;

    @include over-md() {
      padding: 1.7rem 1.6rem;
    }
  }

  &-info {
    @include col(10);

    @media #{$breakpoint-md-up} {
      min-height: 65px;
      padding: 0;
    }

    @include over-md() {
      min-height: 90px;
    }

    @include over-lg() {
      min-height: 65px;
    }
  }

  &-ctrls {
    @include col(2);
  }

  &-name {
    font-size: 1.8rem;
    font-weight: 600;
    color: $color-primary;

    @include over-md() {
      font-size: 2.4rem;
      line-height: 29/24+em;
    }
  }

  &-note {
    font-size: 1.4rem;
    font-weight: 300;
    color: $color-secondary;

    @include over-md() {
      font-size: 1.8rem;
      line-height: 22/18+em;
    }
  }

  &-ctrls {
    text-align: right;
  }

  &-ctrl {
    font-size: 1.5rem;
  }

}
