.color {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	&._param-description {
		align-self: center;
		flex-direction: column-reverse;
	}

	&__item {
		display: flex;
		align-items: center;
		min-width: 7.4rem;
		width: 100%;

		@include over-md() {
			width: auto;
			min-width: 18.4rem;
			flex-direction: column;
		}

		@include under-md() {
			&.poor {
				order: 5;
			}

			&.fair {
				order: 4;
			}

			&.good {
				order: 3;
			}

			&.very {
				order: 2;
			}

			&.excellent {
				order: 1;
			}
		}

		._accent {
			border: 2px solid $color-color;
		}
	}

	&._param-description & {

		&__item {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0;
			min-width: 0;
			max-width: none;
			height: 4.5rem;
		}
	}

	$colors-list: (
		poor: '#fffe8c',
		fair: '#ffffcc',
		good: '#fefef0',
		very: '#ededed',
		excellent: '#fafafa'
	);

	&__image {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		min-height: 4.6rem;
		width: 100%;
		position: relative;
		margin-top: 3.5rem;
		margin-bottom: 1.2rem;
		order: 3;

		img {
			max-width: 100%;
		}

		&:before {
			content: '';
			position: absolute;
			left: 0;
			width: 7px;
			height: 100%;

			@each $key,$val in $colors-list {
				.#{$key} & {
					background-color: #{$val};
				}
			}
		}

		@include under-md() {
			margin: 0;
			justify-content: flex-start;
			padding-left: 20px;
			align-items: center;
			min-height: 5.6rem;
			max-width: 70px;
		}

		@include over-md() {
			&:before {
				width: 100%;
				height: 9px;
				top: -20px;
			}
		}
	}

	&._param-description & {

		&__image {
			display: flex;
			align-items: center;
			margin: 0 1rem 0 0;
			min-height: 0;
			height: 100%;
			width: auto;

			&:before {
				position: static;
				flex: 0 0 .5rem;
				display: block;
				margin-right: 1rem;
				height: 100%;
			}

			img {
				width: 4.5rem;
				height: auto;
			}
		}

		&__item {

			&:last-child {

				.color__image:before {
					border-radius: .5rem .5rem 0 0;
				}
			}

			&:first-child {

				.color__image:before {
					border-radius: 0 0 .5rem .5rem;
				}
			}
		}
	}

	&__title {
		position: relative;
		font-weight: 300;
		font-size: 1rem;
		line-height: 12/10+em;
		color: #000;
		text-align: center;
		order: 2;

		&:before,
		&:after {
			content: '';
			position: absolute;
		}

		@include under-md() {
			display: flex;
			color: #8b8b8b;
			width: 36%;
			height: 100%;
			align-items: center;
			border-right: 2px solid rgba(	151, 151, 151, 0.22);
			margin-right: 25px;
			padding-right: 20px;
			max-width: 65px;
			min-width: 65px;
			justify-content: flex-end;

			&:before {
				width: 6px;
				height: 6px;
				background-color: $color-color;
				border-radius: 50%;
				right: 10px;
				top: 50%;
  			transform: translateY(-50%);
			}

			&:after {
				height: 0;
				width: 30px;
				right: -20px;
				border-top: 2px solid rgba(151, 151, 151, 0.22);
				background-color: $bg-primary;

				.poor & {
					height: 52%;
					top: 48%;
				}

				.excellent & {
					top: 0;
					height: 52%;
					border-top: none;
					border-bottom: 2px solid rgba(151, 151, 151, 0.22);
				}
			}
		}

		@include over-md() {
			order: 1;
			margin-bottom: 2.9rem;
			font-size: 2.4rem;
			border-bottom: 1px solid $ctrl-compare-color;
			width: 100%;
			padding-bottom: 10px;

			&:before,
			&:after {
				left: 0;
				right: 0;
			}

			&:before {
				top: 100%;
				width: 0;
				height: 22px;
				margin: 0 auto;
				border-right: 2px solid $ctrl-compare-color;
				background-color: $bg-primary;

				.poor &,
				.excellent & {
					width: 50%;
					margin: 0;
				}

				.excellent & {
					left: 50%;
					border-right: none;
					border-left: 2px solid $ctrl-compare-color;
				}
			}
		}
	}

	&._param-description & {

		&__title {
			margin: 0 0 0 .5rem;
			padding: 0;
			width: auto;
			min-width: 6.5rem;
			border: none;
			font-weight: 400;
			font-size: 1.2rem;
			line-height: 8/7+em;
			color: #000;
			text-align: left;

			&:before,
			&:after {
				display: none;
			}
		}
	}

	&__level {
		flex-shrink: 0;

		@include over-md() {
			order: 4;
		}
	}

	&._param-description & {

		&__level {
			margin-right: .3rem;
			order: -1;
		}
	}

	&__letters {
		position: relative;
		font-size: 1rem;
		font-weight: 600;
		order: 4;
	  color: #8b8b8b;

		@include over-md() {
			font-size: 2.4rem;
  		font-weight: 100;
			order: 2;
			margin-bottom: 1rem;
		}

		b {
			position: absolute;
			white-space: nowrap;
			top: -18px;
			left: 0;
			color: $color-color;
		}

		span {
			color: $color-description;
			padding: .2rem 1rem;

			@include over-md() {
				padding: .2rem 0.5rem;
			}
		}
	}

	&._param-description & {
		&__letters {
			font-size: 1.2rem;
			order: 4;
			margin-bottom: 0;
		}
	}

	&.education__type,
	&._modal {
		@include under-md() {
			max-width: 285px;
		}
	}

	&._modal {
		@include under-md() {
			margin: 2rem auto;
		}

		// custom small screen view
		@media screen and (max-width: 400px) {
			max-width: 250px;

			.color__image {
				padding-left: 15px;
				max-width: 60px;
				min-width: 60px;
			}
		}

		.color__item {
			@media screen and (min-width: $md+px) and (max-width: $lg - 1+px) {
				min-width: 17.4rem;
			}
		}

		.color__letters {
			span {
				padding: .2rem .5rem;
			}

			b {
				@include over-md() {
					top: 34px;
					font-size: 1.5rem;
				}
			}
		}

	}
}
