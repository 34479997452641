.comparison {
	position: relative;
	display: flex;
	margin: 2.6rem -2rem 0;
	padding-top: 6.8rem;

	@include under-md() {
		background-color: transparent;
		background-image:
			linear-gradient(to bottom, transparent, transparent 12.6rem, #fff 12.6rem),
			url("/img/comparison/comparison-bg.jpg");
		background-repeat: no-repeat;
		background-position: center top;
		background-size: 100%;
	}

	@include over-md() {
		margin: 0 auto;
		width: 83rem;
		padding-top: 0;
		max-width: 100%;
	}

	&__close {
		position: absolute;
		top: -5rem;
		right: 0;
		z-index: 110;

		@include under-md() {
			top: 1.2rem;
			right: 2rem;
			
			&.ctrl {
				color: #fff;
			}
		}

		.ico {
			font-size: 2rem;

			@include over-md() {
				font-size: 3.6rem;
			}
		}
	}

	&__title {
		position: absolute;
		top: 4.6rem;
		left: 0;
		right: 0;
		color: #fff;
		text-align: center;
		z-index: 1;

		@include over-md() {
			display: none;
		}
	}

	&__item {
		flex: 0 1 50%;
		min-width: 50%;
		max-width: 50%;

		@include over-md() {
			display: flex;
			flex-direction: column;
			align-items: stretch;
		}
	}

	&__header {

		@include over-md() {
			display: flex;
			flex-direction: column;
		}
	}

	&__item:nth-child(2n+1) & {

		&__padding {
			padding: 0 .2rem 0 1.5rem;

			@include over-md() {
				padding: 0 3.8rem 0 0;
			}
		}
	}

	&__item:nth-child(2n) & {

		&__padding {
			padding: 0 1.5rem 0 .2rem;

			@include over-md() {
				padding: 0 0 0 3.8rem;
			}
		}
	}

	&__shape {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		max-width: 100%;

		.label {
			display: inline-block;
			vertical-align: top;
			max-width: 100%;
		}

		@include over-md() {
			align-self: stretch;

			.label {
				display: inline-flex;
			}
		}
	}

	&__note {
		margin-right: .7rem;
		font-weight: 600;
		font-size: 2.4rem;
		line-height: 29/24+em;
		letter-spacing: -.026/24+em;
		color: #000;

		@include under-md() {
			display: none;
		}
	}

	&__gia-btn {
		@extend %button;
		margin: .5rem;
		width: 2.4em;
		line-height: 0;

		img {
			max-width: 100%;
		}

		@include over-md() {
			order: -1;
			margin: 0 0 .3rem;
			width: 6.7rem;
		}
	}

	&__price {
		margin: .3rem 0 1.3rem;
		line-height: 29/24+em;
		text-align: center;

		@include over-md() {
			order: -1;
			margin-bottom: 1rem;
			font-size: 6rem;
			line-height: 72/60+em;
		}
	}

	.good-inner {

		@include over-md() {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 7.6rem;
			min-height: 22.6rem;
			padding-top: 3.3rem;
			padding-bottom: 3.3rem;
		}

		.good-pic {

			@include over-md() {
				max-width: none;
			}
		}

		&_header {

			@include over-md() {
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
				padding: 1.6rem 0 1.6rem 3.4rem;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}

			.label {

				@include over-md() {
					height: 2.3rem;
					padding: 0 2rem;
					border-radius: 1.2rem;
					line-height: 2.3rem;
				}
			}
		}
	}

	&__image-text {
		display: none;

		@include over-md() {
			position: absolute;
			bottom: 1.9rem;
			left: 50%;
			transform: translateX(-50%);
			display: block;
			opacity: .51;
			font-weight: 100;
			font-size: 1.2rem;
			line-height: 14/12+em;
			color: #000;
		}
	}

	&__params {

		@include over-md() {
			order: 1;
		}
	}

	&__param {
		display: flex;
		align-items: center;
		min-height: 4rem;

		&:nth-child(2n+1) {
			background: #f7f7f7;
		}

		&-title {
			margin-right: .25rem;
			min-width: 2.1rem;
			font-weight: 700;
			text-align: right;

			@include over-md() {
				margin-right: 1rem;
				min-width: 4.2rem;
				font-size: 1.6rem;
				line-height: 19/16+em;
			}
		}

		@include over-md() {
			display: flex;
			align-items: center;
			min-height: 8rem;

			&:nth-child(n) {
				background: transparent;
			}
		}

		&-inner {

			@include over-md() {
				height: auto;
				padding: 0;
			}
		}

		&-image {
			display: none;

			@include over-md() {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 2.6rem;
				width: 5.5rem;

				img {
					max-width: 100%;

					&._clarity {
						max-width: 4.2rem;
					}
				}
			}
		}
	}

	&__item:nth-child(2n+1) & {

		&__param {
			padding-left: 3.5rem;
		}
	}

	&__item:nth-child(2n) & {

		&__param {
			padding-left: 2.2rem;

			@include over-md() {
				padding-left: (3.5+3.8)+rem;
			}
		}
	}

	&__level {
		margin-right: .25rem;

		@include over-md() {
			margin-right: 1rem;
			@include volume("comparison__level-over-md", 42, 6, 26, 11);
		}

		&-title {

			@include over-md() {
				font-weight: 100;
				font-size: 2.4rem;
				line-height: 29/24+em;
			}
		}
	}

	&__diamond {
		position: relative;
		margin-right: .65rem;

		&:after {
			position: absolute;
			top: .3rem;
			left: 0;
			right: 0;
			content: '?';
			font-family: $font;
			font-weight: 700;
			font-size: .7rem;
			line-height: 8/7+em;
			color: #fff;
			text-align: center;
		}

		@include over-md() {
			margin-right: .85rem;

			&:before {
				font-size: 2.4rem;
			}

			&.ico {

				&:after {
					top: .4rem;
					font-size: 1.4rem;
					line-height: 17/14+em;
				}
			}
		}
	}

	&__quality {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 1rem;

		@include over-md() {
			flex-direction: row-reverse;
			justify-content: space-between;
			padding: .6rem 0 .9rem;
			border-top: .1rem solid $ctrl-color;
			border-bottom: .1rem solid $ctrl-color;
			margin-bottom: 0.5rem;
		}

		&-level {
			font-size: 2rem;
			align-self: center;
			margin-bottom: .3rem;
			padding: 0;
			width: 2.1em;
		}

		&-title {
			@extend .text-2;
			font-weight: 300;
			color: #707070;
			text-align: center;

			@include over-md() {
				font-weight: 100;
				font-size: 2.4rem;
				color: #000;
			}
		}
	}

	&__item:nth-child(2n+1) & {

		&__quality {

			margin-left: 3.5rem;
			margin-right: 3.8rem;
		}
	}

	&__item:nth-child(2n) & {

		&__quality {
			@include over-md() {
				margin-left: (3.5 + 3.8)+rem;
			}
		}
	}

	&__cheaper {
		padding-top: .6rem;
		font-weight: 100;
		font-size: 1.6rem;
		line-height: 19/16+em;
		color: #000;

		span {
			font-weight: 700;
			color: $btn-bg;
		}

		@include under-md() {
			display: none;
		}
	}

	&__item:nth-child(2n+1) & {

		&__cheaper {
			padding-left: 3.5rem;
			padding-right: 3.8rem;
		}
	}

	&__item:nth-child(2n) & {

		&__cheaper {
			padding-left: (3.5 + 3.8)+rem;
		}
	}

	&__btn {

		@include over-md() {
			order: 2;
			display: flex;
			justify-content: center;
			margin-top: -1rem;

			.btn {
				flex: 1 1 30rem;
				max-width: 30rem;
				min-width: 0;
				height: 6.6rem;
				font-weight: 700;
				font-size: 2.4rem;
				line-height: 29/24+em;
				color: #fff;
			}
		}
	}

	&__item:nth-child(2n+1) & {

		&__btn {
			padding: 0 1.4rem 0 2rem;

			@include over-md() {
				padding: 0 3.8rem 0 0;
			}
		}
	}

	&__item:nth-child(2n) & {

		&__btn {
			padding: 0 2rem 0 1.4rem;

			@include over-md() {
				padding: 0 0 0 3.8rem;
			}
		}
	}

	&__icon {
		position: absolute;
		top: 50%;
		left: 100%;
		margin-left: 3.8rem;
		transform: translateX(-50%) translateY(-50%);

		path {

			&:nth-child(-n+2) {
				fill: $btn-bg;
			}

			&:nth-child(n+3) {
				fill: $color-carat;
			}
		}

		@include under-md() {
			display: none;
		}
	}
}
