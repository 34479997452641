.progress {
  display: block;
  width: 100%;
  height: $gutter / 2;
  background: $progress-bg;
  border-radius: 5px;
  position: relative;

  &-bar {
    display: block;
    min-width: 0;
    max-width: 100%;
    background: $progress-color;
    border-radius: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    &.-carat {
      background: $color-carat;
    }

    &.-cut {
      background: $color-cut;
    }

    &.-clarity {
      background: $color-clarity;
    }

    &.-color {
      background: $color-color;
    }

    &.-secondary {
      background: $progress-color-secondary;
    }

    &_description {
      display: flex;
      position: relative;
      top: -20px;
      padding-top: $gutter;
      padding-bottom: $gutter;
      min-height: 3.2rem;

      &-block {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-top: 20px;
        cursor: pointer;

        &.-right {
          text-align: right;
        }

        &.-left {
          text-align: left;
        }

        &:hover {
          .product-details_item-description {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }

      &-param {
        margin-bottom: 0.4rem;
        font-size: 1.5rem;
        font-weight: 600;
        color: $progress-description-color;
      }

      &-name {
        margin-bottom: 0.3rem;
        font-size: 1.5rem;
        font-weight: 300;
        color: $progress-name-color;
      }

      .ico {
        &-Charge {
          font-size: 1.8rem;
          color: $color-accent;
        }
      }
    }

    &_level {
      display: inline-flex;
      vertical-align: top;
      margin-top: 0.3rem;
    }
  }

  &-container {
    padding-top: $gutter;
    padding-bottom: 2 * $gutter;
    position: relative;
  }

  &-name {
    @include textOverflow();
    padding-bottom: $gutter / 2;
    font-size: 1.4rem;
    font-weight: 300;
    color: $color-description;

    &.-accent {
      font-size: 1.8rem;
      font-weight: 900;
      color: $color-accent;
    }
  }

  &-note {
    @include textOverflow();
    max-width: 100%;
    font-size: 1.2rem;
    font-weight: 300;
    text-align: right;
    color: $color-description;
    position: absolute;
    right: 0;
    bottom: 0;

    &.-accent {
      font-weight: 700;
      color: $color-accent;
    }
  }

  &.-lg {
    height: $gutter;
    border-radius: $gutter;
  }

  &.-multiply {
    .progress-bar {
      border-radius: 0;
      &:first-child {
        border-radius: 5px 0 0 5px;
      }
      &:last-child {
        border-radius: 0 5px 5px 0;
      }
    }

    &.-lg {
      .progress-bar {
        border-radius: 0;
        &:first-child {
          border-radius: $gutter 0 0 $gutter;
        }
        &:last-child {
          border-radius: 0 $gutter $gutter 0;
        }
      }
    }
  }
}
