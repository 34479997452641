.slick-slider {
	padding-bottom: 7rem;
	&.slick-slider-custom {
		box-shadow: 0px 0px 0px 0px rgba(0,0,0,0) !important; 
	}
	.slick-initialized {
		box-shadow: -23px 2px 104px 2px rgba(0,0,0,1);	
	}
	.slick-arrow {
		@extend %button;
		position: absolute;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 5.3rem;
		height: 5.3rem;
		border-radius: 50%;
		border: .1rem solid $ctrl-border;
		cursor: default;
		font-size: 0;
		line-height: 0;
		color: transparent;

		svg {
			width: 1.2rem;
			height: auto;
		}

		path {
			fill: #636363;
		}

		&.slick-disabled {
			opacity: .5;
		}

		&:not(.slick-disabled):hover {
			border-color: $btn-bg-hover;
			background: $btn-bg-hover;
			cursor: pointer;

			path {
				fill: #fff;
			}
		}

		&.slick-prev {
			right: 10.3rem;
			
			svg {
				margin-left: -.4rem;
				transform: rotate(180deg);
			}
		}

		&.slick-next {
			right: 3rem;
			
			svg {
				margin-right: -.4rem;
			}
		}
		&.simple-arrow {
			@extend %button;
			background-color: transparent;
			border: none;
			position: absolute;
			cursor: pointer;
			font-size: 0;
			line-height: 0;
			z-index: 1;
			color: transparent;
			img {
				width: 28px;
    			height: 30px;
			}
			&.simple-arrow-prev {
				left: 28px;
				top: 165px;
	
			}
			&.simple-arrow-next {
				right: 28px;
				top: 165px;
			}
			&.slick-disabled {
				display: none;
			}
			&:not(.slick-disabled):hover {
				cursor: pointer;
				background-color: transparent;
				path {
					fill: #fff;
				}
			}
		}
	
	}
	.slick-dots-container {
		-webkit-box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.0862489);
		-moz-box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.0862489);
		box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.0862489);
		height: 57px;
		width: 603px;
		border-radius: 14px;
		display: inline-flex !important;
		justify-content: center;
		position: absolute;
		bottom: -40px;
		background-color: white;
		left: 18px;
		li {
			margin-left: 3px;
			margin-right: 10px;
		}
		
	}
	.slick-thumb {
		list-style: none;	
		@include under-sm() {	
			left: 0px;
			width: 100%;
			justify-content: center;
		}	
		
		@include under-md() {
			width: 100%;
			left: 0px;
		}
		
		@media (min-width: 1200px) and (max-width: 1320px) {
			left: 0px;
			width: 100%;
		}
		
		img {
			height: 53px;
			background-color: transparent;
		}
	}
}