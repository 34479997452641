.info-text-ring {
  color: $color-primary;
  font-size: 18pt;
  opacity: 0.4;
  text-align: center;
}
.info-colors-ring {
  text-align: center;
}
.info-container-ring {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;

  div {
    font-size: 2rem;
    margin: 0 20px;
  }

  button.simple-arrow {
    width: 50px;
    height: 50px;
    display: grid;
    place-items: center;
    border: none;
    background-color: white;

    &:disabled {
      filter: grayscale(1);
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}
.info-details-select-category {
  background-color: black;
  color: white;
  border-radius: 100px;
  padding: 5px 0px 5px 10px;
  width: 100px;
}
.colors_container {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-direction: row;
  justify-content: space-around;
}
.dot {
  height: 9px;
  width: 10px;
  border-radius: 50%;
  margin: 1px;
  display: inline-block;
}
.rose {
  background-color: #ff9595;
}
.yellow {
  background-color: #dcc134;
}

.gold {
  background-color: #dcc134;
}
.white-grey {
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
}

.ring-options-selector-container {
  margin-bottom: 20px;

  .shapes-dropdown-custom {
    margin-top: 0.5rem;

    div:hover {
      background-color: $color-accent-secondary;
    }

    .selected {
      color: $color-accent-secondary;

      &:hover {
        background-color: white;
        cursor: default;
      }
    }

    @media (min-width: 1200px) {
      margin-top: 0.5rem;
      width: auto;
      min-width: 6%;
      margin-left: 7px;
    }
  }
}
