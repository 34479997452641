@import "goods-list";
@import "good";

.goods {
  height: 100%;
  padding-top: 2 * $gutter;
  padding-bottom: 2 * $gutter;

  .loader-container {
    display: grid;
    place-items: center;
    height: 50%;
  }

  .page-info {
    color: rgba(0, 0, 0, 0.4);
    font-size: 2rem;
    width: fit-content;
    margin-bottom: 20px;
  }

  .empty-message {
    color: $color-primary;
    font-size: 1.5rem;
    line-height: 1.3;
    padding: 16px;

    @include over-md() {
      font-weight: 300;
      font-size: 3rem;
      line-height: 41/34 + em;
      color: $color-content;
      padding-left: 0;
    }
  }

  .ring-options-selector-container {
    width: fit-content;

    button {
      height: auto;
      line-height: normal;
      padding: 4px 12px;
      font-size: 1.5rem;
    }
  }

  @include over-md() {
    position: relative;
    max-width: 100%;
  }

  &._comparison {
    margin-bottom: 5.7rem;
  }

  &-header {
    padding-bottom: 2 * $gutter;
    position: relative;

    @include over-md() {
      position: static;
      margin-bottom: 5.2rem;
      padding-bottom: 0;
    }

    &_btn {
      position: absolute;
      top: 0;
      right: 0;

      @include over-md() {
        top: auto;
        bottom: 2rem;
        right: 17rem;
      }

      &.ctrl {
        .ico {
          font-size: 3.3rem;
        }
      }
    }
  }

  &-title {
    color: $color-primary;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: -0.15px;
  }

  &-description {
    padding-top: $gutter;
    color: $color-primary;
    font-size: 1.8rem;
    line-height: 1.3;

    @include over-md() {
      font-weight: 300;
      font-size: 3.4rem;
      line-height: 41/34 + em;
      color: $color-content;
    }
  }

  &__compare-message {
    color: #d8000c;
    font-size: 1.6rem;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
  }
}
