.text-section {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding-top: 2.1rem;
	color: $color-secondary;

	@include over-lg() {
		padding-top: 14.2rem;
	}

	h1 {
		margin-bottom: 1.8rem;
		font-weight: 700;
		font-size: 1.6rem;
		line-height: 19/16+em;
		color: $color-primary;
		text-transform: uppercase;

		@include over-md() {
			font-size: 1.8rem;
			line-height: 22/18+em;
		}
	}

	h2 {
		font-size: 1.8rem;
		color: $color-primary;
		margin-bottom: 1rem;
	}

	p {
		@extend .text-1;
		color: $color-secondary;
		line-height: 1.4;

		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}
	}

	h3 {
		font-size: 1.8rem;
		color: #666666;
		font-weight: 600;
	}

	h4 {
		font-size: 1.6rem;
	}

	.btn {
		align-self: flex-end;
		margin-top: 2.6rem;
		min-width: 29rem;
	}

	ul {
		list-style: disc inside;
		margin-left: 2rem;
		font-size: 1.6rem;
		line-height: 1.8;

		&:not(:last-of-type) {
			margin-bottom: 1.5rem;
		}
	}
}
