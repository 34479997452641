.overlay {
  background: rgba(0, 0, 0, .57);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;

  &._transparent {
    background-color: transparent;
  }
}