.goods-list {
  @extend %row-width;

  &.rings-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
  }

  @include over-md() {
    margin: 0;
    min-width: 100%;
    width: auto;
    overflow: hidden;
    min-height: 356px;

    .slick-slider {
      width: 100%;

      .slick-list {
        margin-right: 4rem;
        overflow: visible;
      }
    }
  }

  .good {
    @include col(12);
    padding-bottom: 5 * $gutter;

    &.ring-good {
      margin: 0 5px;
    }

    @media all and (min-width: 640px) {
      padding-bottom: 5 * $gutter;
    }

    @include over-md() {
      flex: 1 1 auto;
      max-width: none;
    }

    @include under-md() {
      &:not(.ring-good) {
        @include slideAnimationDefaults();

        @for $i from 1 through 100 {
          &:nth-child(#{$i}) {
            animation: slide-from-left-animation
              0.85s
              (0.75 * ($i - 1)) +
              s
              forwards;
          }
        }
      }
    }
  }

  .slick-slide {
    @include slideAnimationDefaults();

    @for $i from 1 through 100 {
      &:nth-child(#{$i}) {
        animation: slide-from-left-animation
          0.85s
          (0.75 * ($i - 1)) +
          s
          forwards;
      }
    }
  }
}
