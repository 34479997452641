.product-description {
	display: flex;
	
	@include under-sm() {
		flex-direction: column;
	}
	@include over-sm() {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
	
	@include under-lg() {
		border-bottom: 1px solid #c1c1c1;
	}
	
	@include over-lg() {
		display: flex;
		flex-direction: column;
		align-items: stretch;

		&__details {
			order: -1;
		}

		&__wrapper {
			display: flex;
		}

		&__assurance {
			border-bottom: none;
		}

		&__header {
			margin-bottom: .5rem;
		}

		&__title {
			font-weight: 300;
			font-size: 2.4rem;
			line-height: 29/24+em;
			color: #000;
		}

		&__content {
			padding: 0;

			p {
				font-weight: 300;
				font-size: 1.8rem;
				line-height: 22/18+em;
				color: $dove-gray;
			}
		}

		&__list {
			margin-top: 2.7rem;

			&-item {
				font-weight: 300;
				font-size: 1.8rem;
				line-height: 22/18+em;
				color: $dove-gray;

				&:not(:last-child) {
					margin-bottom: 2.4rem;
				}
			}
		}
	}

	&__included {
		display: flex;
		align-items: center;

		@include over-lg() {
			flex: 0 1 52rem;
			padding: 0;
			border: none;

			&:not(._in-fixed-footer) {
				display: none;
			}
		}

		&-header {

			@include over-lg() {
				margin-bottom: .8rem;
				padding: 0;
			}
		}

		&-title a {
			color: #000544;
		}

		&-content {
			a:hover {
				color: #7be0c3;
			}

			@include over-lg() {
				padding: 0;
			}
		}

		&-list {
			display: flex;

			@include over-lg() {
				align-items: flex-start;
				flex-wrap: wrap;
				padding: 0 !important;
			}

			@include under-lg() {
				flex-direction: column;

				.offer {
					order: -2;
				}
				.chat {
					order: -1;
				}
			}

			li {
				white-space: nowrap;
				
				&.offer a {
					color: #5752c9;

					&:hover {
						color: #7be0c3;
					}
				}

				&.chat a {
					color: #000544;

					&:hover {
						color: #7be0c3;
					}
				}

				@include over-lg() {
					flex: 0 1 50%;
					margin: 0 !important;
					padding: 0 .5rem;
					min-width: 50%;

					&:not(:last-child) {
						margin: 0 0 1.1rem;
					}
				}
			}
		}

		&-image {
			margin-top: 3.7rem;

			@include over-lg() {
				margin-top: 0;
				padding-left: 1rem;
				line-height: 0;
			}
		}
	}
}