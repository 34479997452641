.collapse {

	&__btn {
		cursor: pointer;
	}

	&:not(._under-lg) & {

		&__content {
			display: none;
		}
	}

	&._under-lg & {

		&__content {

			@include under-lg() {
				display: none;
			}
		}
	}

	&._opened & {

		&__content {
			display: block;
		}
	}
}