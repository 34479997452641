.modal-1-bg {
	position: fixed;
	z-index: 1010;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,.57);
}

.modal-1-wrapper {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding: 6.4rem 2.7rem;
	width: 100%;
	height: 100%;
	overflow: auto;
}

.modal-1 {
	position: relative;
	padding: 2.6rem 2.5rem 1.5rem;
	border-radius: .8rem;
	background: #fff;
}