@keyframes slide-from-left-animation {
	from {
		opacity: 0;
		transform: translate3d(-10rem, 0, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
