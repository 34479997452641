@keyframes blink1 {
  0% { background: $ctrl-bg-hover; color: white; transform: scale(1.2); }
  100% { background: white; color: inherit; transform: scale(1); }
}
@keyframes blink2 {
  0% { background: white; color: inherit;  transform: scale(1.2); }
  100% { background: $ctrl-bg-hover; color: white;  transform: scale(1); }
}

.ctrl {
  display: block;
  padding: 0;
  background: none;
  border: 0;
  text-align: center;
  text-decoration: none;

  .ico {
    font-size: 1.4rem;

    &-Plus,
    &-Minus {
      font-weight: 700;
    }
  }

  &:not(:disabled) {
    color: $ctrl-color;
    cursor: pointer;

    &:hover,
    &:focus,
    &.-active {
      color: $ctrl-bg-hover;
      outline: none;
    }
  }

  &.no-focus{
    &:focus,
    &:hover {
      color: $ctrl-color-disabled;
    }
  }

  &:disabled {
    color: $ctrl-color-disabled;
  }

  &.-secondary {
    &:not(:disabled) {
      color: $ctrl-bg-hover;
    }

    &:hover,
    &:focus,
    &.-active {
      color: $ctrl-color;
    }
  }

  &.-inverse {
    &:not(:disabled) {
      color: $ctrl-color-inverse;
    }

    &:hover,
    &:focus,
    &.-active {
      color: $ctrl-color;
    }
  }

  &.-inline {
    display: inline-block;
    vertical-align: top;
  }

  &.-bordered {
    width: 30px;
    height: 30px;
    border-radius: 50%;

    .ico {
      line-height: 28px;
    }

    &:not(:disabled) {
      background: $ctrl-bg;
      border: 1px solid $ctrl-border;
      color: #6c6c6c;

      &:hover {
        background: $ctrl-bg-hover;
        border-color: $ctrl-bg-hover;
        color: $ctrl-color-hover;
      }
    }

    &:disabled {
      background: $ctrl-bg;
      border: 1px solid $ctrl-border-disabled;
      color: $ctrl-color-disabled;
    }
  }

  &.-button-effect {
    box-shadow: 3px 3px 3px lightgray;

    &:first-child {
      animation: blink1 0.2s 3;
      
    }
    &:last-child {
      animation: blink2 0.2s 3;
    }
    
    &.buttons-0 {
      animation-delay: 0;
    }
    &.buttons-1 {
      animation-delay: 0.75s;
    }
    &.buttons-2 {
      animation-delay: 1.5s;
    }
    
    &:active {
      position: relative;
      top: 1px;
      box-shadow: 0 1px 2px lightgray;
    }
  }

  &.-menu {
    &:not(:disabled) {
      color: $ctrl-menu-color;

      &:hover,
      &:focus {
        color: $ctrl-bg-hover;
      }
    }

    &.-inverse {
      &:not(:disabled) {
        color: $ctrl-color-inverse;
      }

      &:hover,
      &:focus,
      &.-active {
        color: $color-link-hover;
      }
    }
  }

  &.-compare {
    &:not(:disabled) {
      color: $ctrl-compare-color;

      &:hover,
      &:focus {
        color: $ctrl-bg-hover;
      }
    }
  }

  &.-md {
    .ico {
      font-size: 2rem;
    }
  }

  &.-lg {
    .ico {
      font-size: 2.8rem;

      &-Menu {
        font-size: 2.5rem;
      }
    }

    &.-bordered {
      width: 52px;
      height: 52px;

      .ico {
        line-height: 50px;
      }
    }
  }

  &.-sm {
    .ico {
      font-size: 1rem;
    }

    &.-bordered {
      width: 17px;
      height: 17px;

      .ico {
        line-height: 15px;
      }
    }
  }
}
