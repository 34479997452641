/* Style the accordion section */
.accordion__section {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(158,158,158,0.2);;
    border-bottom: 1px solid rgba(158,158,158,0.2);;
  }
  
  /* Style the buttons that are used to open and close the accordion panel */
  .accordion {
    background-color:white;
    color: black;
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
  }
  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .accordion:hover,
  .active__accordion {
    background-color:white;
  }
  
  /* Style the accordion content title */
  .accordion__title {
    font-weight: bold;
    flex-direction: row;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.175714px;
  }

  .accordion__title__underline {
    border-bottom: 4px solid #50e3c2;
    line-height: 60px; 
    transition: all 0.3s ease-in-out 0s;
  }

  .accordion__title__icon{
    margin-left: 14px; 
    margin-bottom: -6px;
  }

  
  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }
  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content__container {
    background-color: white;
    overflow: auto;
    transition: max-height 0.6s ease;
    overflow-y: hidden;
  }
  
  /* Style the accordion content text */
  .accordion__subcontainer__info {
    padding: 22px;
    padding-top: 0;
    p {
      display: inline;
      text-align: justify;
      color:black;
      font-weight: lighter; 
      text-align: left;
      // width: 396px;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.197679px;
    } 
    table {
      font-size: 14pt;
      tr{
        td {
          &:first-child{
            color: gray;
            font-weight: lighter;
            padding-left: 10px;
            width: 50%;
          }
          &:nth-child(2){
            color: black;
            text-align: right;
            width: 50%;
            margin-left: 100%;
            padding-left: 40px;
            font-weight: lighter;
          }
        }
      }
    }

  }
  