.header-filter {
  position: sticky;
  padding: 0 (2 * $gutter) (2 * $gutter);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .15);
  margin: (2 * $gutter) (-2 * $gutter) 0;
  background: $bg-primary;
  top: 5.4rem;
  z-index: 1000;

  @include over-md() {
    position: static;
    margin: 0 0 3.5rem 0;
    padding: 0;
    box-shadow: none;
  }

  &.-open {
    background: $bg-secondary;

    @include over-md() {
      background: transparent;
    }
  }

  &_inner {
    display: flex;
    align-items: center;

    @include over-md() {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
    }

    @include over-lg() {
      flex-direction: row;
      align-items: center;
    }
  }

  &.-open & {

    &_inner {

      @include over-md() {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: none;
      }

      @include over-lg() {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  &_title {
    display: none;

    @include over-md() {
      display: block;
      margin-bottom: 1rem;
      font-weight: 700;
      font-size: 2rem;
      line-height: 24/20+em;
      color: #000;
    }

    @include over-lg() {
      margin-bottom: 0;
      margin-right: 2.8rem;
    }
  }

  &_images {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 100%;
    min-width: 0;

    @include over-md() {
      flex: 0 1 auto;
      flex-wrap: nowrap;
      align-items: stretch;
      padding: 0 1.6rem;
      height: 6.2rem;
      border-radius: 3.1rem;
      border: .1rem solid $btn-border;
    }

    &-inner {
      flex: 1 1 auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .ctrl {
        margin-right: 2.8rem;
        margin-top: (2 * $gutter);
        height: 2.5rem;

        @include over-md() {
          margin: 0 1.5rem;
          padding: 0;
          flex: 0 1 auto;
          width: auto;
          height: 4rem;

          img {
            width: auto;
            height: 100%;
            max-width: none;
          }
        }
      }

      @include over-md() {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin: 0;
      }
    }
  }

  &_close {
    @include under-md() {
      padding-top: (2 * $gutter);
    }
    .ctrl {
      width: 100%;
      text-align: right;
    }

    @include over-md() {
      &:not(.-over-md) {
        display: none;
      }
    }

    &.-over-md {
      align-self: center;
      flex: 0 0 auto;
      margin-left: 1.5rem;
      margin-right: 1.2rem;

      @include under-md() {
        display: none;
      }

      .ico {
        font-size: 2.4rem;
      }
    }
  }

  &_button {
    text-align: left;
    position: relative;

    @include over-md() {

      &,
      &.-sm {
        flex: 0 0 auto;
        justify-content: flex-start;
        height: 6.2rem;
        min-width: 20rem;
        border-radius: 3.1rem;
      }
    }

    &-text {
      display: inline-block;
      vertical-align: middle;

      @include over-md() {
        font-size: 2.4rem;
      }
    }

    &-img {
      display: inline-block;
      height: 3rem;
      margin-left: $gutter;
      vertical-align: middle;
      max-width: 3rem;

      @include over-md() {
        margin-left: 2rem;
        margin-right: 4rem;
        height: 4.2rem;
        max-width: 100%;
        object-fit: scale-down;
      }
    }

    .ctrl {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 2 * $gutter;
      line-height: 0;

      @include over-md() {

        svg {
          height: 2.5rem;
          width: auto;
        }
      }
    }
  }

  &_img {
    height: 100%;
  }
}
