.education-modal {
	text-align: center;

	&__title {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 2.6rem;
		text-align: center;
	}

	&__icon {
		margin-right: .5rem;
	}

	&__p {
		margin-top: 1.6rem;
		@extend .text-2;
		font-weight: 300;
		color: $color-description;
		text-align: center;
	}

	&__btn {
		margin-top: 2.9rem;
	}
}
