@import 'details';
@import 'info';
@import 'assistant';
@import 'params';
@import 'gia-cert';
@import 'media-slider';
@import 'diamondEducationTabs';

.product {
  padding-top: $gutter;

  &-views {
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: -.1px;
    color: $product-view-color;

    &._product-page {

      @include over-lg() {
        margin-bottom: .4rem;
      }
    }
  }

  &-info {

    &_compare {

      @include over-md() {
        z-index: 1;
      }

      svg {
        display: block;
        width: 2.6rem;
        height: auto;
      }

      &.-lg {

        svg {
          width: 3.8rem;

          @include over-md() {
            width: 5.8rem;
          }
        }
      }

      path {
        fill: $ctrl-color-secondary;
      }

      &.-active {

        path {

          &:nth-child(3) {
            fill: $color-carat;
          }
        }
      }

      &.-one {

        path {

          &:nth-child(2) {
            fill: $color-accent;
          }

          &:nth-child(3) {
            fill: $color-carat;
          }
        }
      }

      &.-two {

        path {

          &:nth-child(1),
          &:nth-child(2) {
            fill: $color-accent;
          }

          &:nth-child(3),
          &:nth-child(4) {
            fill: $color-carat;
          }
        }
      }
    }
  }

  &-description__content {
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
}

.shapes-dropdown {
  font-size: 1.2rem;
  z-index: 300;
  position: absolute;
  background-color: $bg-primary;
  width: 50%;
  border: solid 1px #2c2c2c;
  font-weight: 900;
  color: $color-primary;
  text-transform: uppercase;

   > div {
     cursor: pointer;
     padding: 5px 10px;

     &:hover {
       color: $bg-primary;
       background-color: $color-primary;
     }
   }
}
