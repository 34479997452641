.education-icon {
	position: relative;
	line-height: 0;

	@include over-md() {
		width: 4.1rem;
		height: auto;

		svg {
			width: 100%;
			height: auto;
		}
	}

	span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translatex(-50%) translateY(-50%);
		@extend .text-2;
		font-weight: 700;
		color: #fff;

		@include over-md() {
			top: 0;
			transform: translateX(-50%);
			font-size: 2.4rem;
		}
	}

	&._cerulean {

		path {
			fill: $color-color;
		}
	}

	&._yellow {

		path {
			fill: $color-clarity;
		}
	}

	&._rose {

		path {
			fill: $color-carat;
		}
	}
}