.shipping-billing {
  width: 100%;

  @include under-md() {
    .text-input {
      min-height: 70px;
    }
  }

  @include over-md() {
    display: flex;
    align-items: stretch;
    max-width: 100%;
  }

  &__item {
    @include over-md() {
      flex: 0 1 50%;
      min-width: 0;

      &:first-child {
        padding-right: 2rem;
        border-right: 0.1rem solid $btn-bg-hover-secondary;
      }

      &:last-child {
        padding-left: 2rem;
      }
    }
  }

  &._billing & {
    @include under-md() {
      &__item:last-child {
        display: none;
      }
    }
  }

  &._shipping & {
    @include under-md() {
      &__item:first-child {
        display: none;
      }
    }
  }

  &__form {
    @include over-md() {
      margin: 0 auto;
      width: 100%;
      max-width: 44rem;
    }
  }

  &__title {
    @include over-md() {
      margin-bottom: 2.4rem;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 37/24 + em;
      text-transform: uppercase;

      .checkbox {
        label {
          span {
            text-transform: none;
          }
        }
      }
    }
  }

  &__submit {
    @include over-md() {
      display: flex;
      justify-content: flex-end;

      .btn {
        max-width: 30rem;
        min-width: 30rem;
      }
    }
  }
}
