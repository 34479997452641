.form-control {
	display: flex;
	flex-direction: column;
	align-items: stretch;

	&__label {
		align-self: flex-start;
		display: block;
		margin-bottom: 1rem;
		@extend .text-2;
		font-weight: 600;

		@include over-md() {
			margin-bottom: 1rem;
			font-size: 1.8rem;
			line-height: 22/18+em;
		}
	}

	&__row {
		display: flex;
		align-items: center;

		&._card-number {
			justify-content: space-between;

			input {
				width: calc(25% - .6rem);
			}
		}
	}

	&__input {

		&._expiration-month {
			flex: 0 0 178/441*100%;
			max-width: 178/441*100%;
			min-width: 178/441*100%;
			margin-right: 2.7rem;

			@include over-md() {
				margin-right: 4.6rem;
			}
		}

		&._expiration-year {
			flex: 0 0 123/441*100%;
			max-width: 123/441*100%;
			min-width: 123/441*100%;
		}

		&._cvv {
			margin-right: 1.2rem;
			max-width: 6.3rem;
			min-width: 6.3rem;

			@include over-md() {
				flex: 0 0 106/441*100%;
				max-width: 106/441*100%;
				min-width: 106/441*100%;
			}
		}
	}

	&__comment {
		@extend .text-2;
		font-weight: 600;
		color: $color-note;

		@include over-md() {
			font-size: 1.8rem;
			line-height: 22/18+em;
		}
	}
}