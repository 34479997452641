.nav {
  padding: (4 * $gutter) 0 (2 * $gutter);
  overflow-y: auto;
  overflow-x: hidden;

  &-item {

    &:not(:first-child) {
      padding-top: 3 * $gutter;
    }
  }

  &-link {
    @extend %button;
    display: block;
    width: 100%;
    padding: 0 (2 * $gutter);
    text-align: right;
    font-size: 1.6rem;
    font-weight: 300;

    @include over-md() {
      padding: 1.2rem 2.3rem;
      font-weight: 100;
      font-size: 3rem;
      line-height: 1.15;
    }

    &:hover {
      color: $btn-bg-hover;
    }
  }
}
