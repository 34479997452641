@mixin centerBlock($width, $maxWidth, $mr: auto, $ml: auto) {
  width: $width;
  max-width: $maxWidth;
  margin-right: $mr;
  margin-left: $ml;
}

@mixin textOffset() {
  overflow: hidden;
  text-indent: $text-offset;
}

@mixin flexWrap($wrap: wrap) {
  flex-wrap: $wrap;
}

@mixin textOverflow($height: auto) {
  height: $height;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: $height;
  white-space: nowrap;
}

@mixin shapeRotate($deg) {
  transform: rotate($deg) translateY(-95px);
  .ctrl {
    transform: rotate(-$deg);
  }
}

@mixin icomoon($icomoon-font-family: Joolez_font, $lh: 1) {
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: $lh;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Placeholder text
@mixin placeholder($color, $opacity) {
  // Firefox
  &::-moz-placeholder {
    color: $color;
    opacity: $opacity; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }
  &:-ms-input-placeholder {
    color: $color;
    opacity: $opacity;
  } // Internet Explorer 10+
  &::-webkit-input-placeholder  {
    color: $color;
    opacity: $opacity;
  } // Safari and Chrome
}

// Media Queries
@mixin under-xxlg() {
  @media (max-width: ($xxlg - 1)+px) {
    @content
  }
}
@mixin over-xxlg() {
  @media (min-width: $xxlg+px) {
    @content
  }
}

@mixin under-xlg() {
    @media (max-width: ($xlg -1)+px) {
      
    }
}

@mixin under-lg() {
  @media (max-width: ($lg - 1)+px) {
    @content
  }
}
@mixin over-lg() {
  @media (min-width: $lg+px) {
    @content
  }
}

@mixin under-md() {
  @media (max-width: ($md - 1)+px) {
    @content
  }
}
@mixin over-md() {
  @media (min-width: $md+px) {
    @content
  }
}

@mixin under-sm() {
  @media (max-width: ($sm - 1)+px) {
    @content
  }
}
@mixin over-sm() {
  @media (min-width: $sm+px) {
    @content
  }
}

// Default slide left animation
@mixin slideAnimationDefaults() {
  backface-visibility: hidden;
  transform: translate3d(-10rem, 0, 0);
  opacity: 0;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.2, 1);
}

@mixin shakeAnimation() {
  @keyframes shake {
    0% { transform: rotate(0) translateX(0); }
    10% { transform: rotate(5deg) translateX(4px); }
    20% { transform: rotate(0) translateX(0); }
    80% { transform: rotate(0) translateX(0); }
    90% { transform: rotate(-5deg) translateX(-4px); }
    100% { transform: rotate(0) translateX(0); }
  }
}
