@import "../nav/nav";

.sidepanel {
  @include row();
  @include row-flex-column();
  @include flexWrap(nowrap);
  @include start();
  width: 80%;
  max-width: 400px;
  padding-bottom: $sidepanelFooterHeight;
  background: $bg-primary;
  box-shadow: -3px 0 4px rgba(0, 0, 0, 0.13);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;

  .RingIcon::after {
    content: $ico-Ring;
    font-size: 3.8rem;
  }

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    bottom: -100%;
    background: #fff;
  }

  &._hidden {
    display: none;
  }

  &-header {
    @include row();
    @include row-flex-column();
    @include end();
    flex-shrink: 0;
    height: $sidepanelHeaderHeight;
    padding: 1.5 * $gutter;
    background: $color-accent;
    color: $color-inverse;
    position: relative;

    @include over-md() {
      padding: 2.1rem 2.3rem;
      height: 17.3rem;
    }

    &_close {
      position: absolute;
      top: 1.5 * $gutter;
      right: 1.5 * $gutter;

      @include over-md() {
        top: 2.6rem;
        right: 2.5rem;
      }
    }

    &_link {
      display: flex;
      font-size: 1.6rem;
      font-weight: 700;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;

      @include over-md() {
        font-size: 3rem;
      }
    }
  }

  &-footer {
    height: $sidepanelFooterHeight;
    padding: 1.5 * $gutter;
    text-align: right;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    &_link {
      border: 0;
      outline: none;
      padding-left: 5rem;
    }

    &_img {
      border: 0;
    }
  }
}

.__grey {
  color: #4d4d4d;
}

.fb_chat__hidden {
  display: none;
}
