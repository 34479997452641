.shop-by {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -1rem -1rem -2.5rem;
  padding-top: 1rem;

  @include over-sm() {
    flex-grow: 1;
    align-items: center;
    padding-top: 0;
    margin: 0 -1rem;
  }
}
