.order-review {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 2.2rem;

	&__table {
		display: table;
		border-collapse: collapse;
		width: 100%;
		padding: 0;
		border: none;
		color: $color-content;

		&-tr {
			display: table-row;

			&._divider {

				.order-review__table-td:nth-child(n) {
					position: relative;

					&:before {
						content: '';
						position: absolute;
						bottom: 6rem;
						left: 0;
						right: 0;
						height: .1rem;
						background: $btn-border;
					}
				}
			}
		}

		&-td {
			display: table-cell;
			vertical-align: top;

			&._expand {
				width: 100%;
			}

			&:not(:last-child) {
				padding-right: 1rem;
			}

			&._c-green {
				color: $color-link-hover;
			}
		}

		&:not(:last-child) & {

			&-td {
				padding-bottom: 2.8rem;
			}
		}
	}

	&__slider {

		&._bd-b {
			margin-bottom: 2.8rem;

			// &:after {
			// 	content: '';
			// 	position: absolute;
			// 	bottom: -1.4rem;
			// 	left: 1rem;
			// 	right: 1rem;
			// 	height: .1rem;
			// 	background: $btn-border;
			// }
		}
	}
}