.product-assistant {
  margin: $gutter 0;
  border-bottom: 1px solid $color-border;

  &._product-page {

    @include over-lg() {
      margin: 0 0 2.3rem;
      border: none;
    }
  }

  &_header {
    padding-top: $gutter / 2;
    padding-bottom: $gutter / 2;
  }

  &._product-page & {

    &_header {
      display: flex;

      @include over-lg() {
        padding: 0;
      }
    }
  }

  &_title {
    font-size: 1.4rem;
    font-weight: 400;
  }

  &._product-page {

    .product-assistant_title {
      cursor: pointer;

      &:not(._active) {
        opacity: .5;
      }

      &:not(:last-child) {
        margin-right: 5rem;
      }

      @include over-lg() {
        font-weight: 300;
        font-size: 2.4rem;
        line-height: 29/24+em;
        color: #000;
      }
    }
  }

  &_content {
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 1.57;
    color: $color-content-old;

    p {
      margin-bottom: .5rem;
    }
  }

  &._product-page & {

    &_content {

      @include over-lg() {
        font-size: 2rem;
        line-height: 1.5;
      }
    }
  }

  .diamond-assistant {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-height: 4.3rem;

    .title {
      max-width: 57px;
      flex-basis: 34%;

      @include over-md() {
        max-width: 115px;
      }

      @include over-lg() {
        max-width: 190px;
        min-width: 190px;
      }
    }
  }

  &_icon {
    max-width: 90px;
    margin-right: 1rem;
    margin-left: 1rem;
    width: 50px;

    @include over-md() {
      width: 100px;
      margin-right: 2rem;
      margin-left: 2rem;
    }

    &:hover {
      + div {
        display: block;
      }
    }
  }

  &_help-text {
    display: none;
    font-size: 1.2rem;
    color: $color-description;
    line-height: normal;

    @include over-md() {
      font-size: 1.4rem;
    }

    .product-param_controls {
      display: inline-block;
      margin-left: 5px;

      .ctrl {
        &:hover {
          color: $color-description;
          background: transparent;
          border-color: $color-description;
        }

        + .ctrl {
          margin-left: 5px;
        }
      }
    }
  }
}
