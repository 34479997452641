.title-1 {
	font-weight: 700;
	font-size: 1.8rem;
	line-height: 22/18+em;
	color: #000;
	letter-spacing: -.02em;
}

.title-2 {
	font-weight: 700;
	font-size: 2rem;
	line-height: 24/20+em;
	color: #000;
}

.text-1 {
	font-weight: 400;
	font-size: 1.8rem;
	line-height: 22/18+em;
	color: #000;
	letter-spacing: -.02px;
}

.text-2 {
	font-weight: 400;
	font-size: 1.4rem;
	line-height: 17/14+em;
	color: #000;
	letter-spacing: -.015em;
}

.text-3 {
	font-size: 2.4rem;
	line-height: 29/24+em;
}

.text-4 {
	font-weight: 400;
	font-size: 1.6rem;
	line-height: 1.5;
	color: #000;
}

//font-weight
.fw-300 {
	font-weight: 300;
}

.fw-600 {
	font-weight: 600;
}

.fw-700 {
	font-weight: 700;
}

//colors
.c-secondary {
	color: $color-secondary;
}

//text-transform
.tt-u {
	text-transform: uppercase;
}
