.education {
	position: fixed;
	z-index: 1020;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	padding: 6.2rem 2rem 0;
	border-bottom: .2rem solid $color-accent;
	background: #fff;
	overflow: auto;

	@include over-md() {
		padding: 3.2rem 3.2rem 0;
	}

	&__container {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		min-height: 660px;
		position: relative;
		height: 100%;
	}

	&__close {
		position: fixed;
		top: 1.2rem;
		right: 1.7rem;
		line-height: 0;

		.ico {
			font-size: 2rem;
		}

		@include over-md() {
			top: 3.9rem;
			right: 4.3rem;

			.ico {
				font-size: 3.6rem;
			}
		}
	}

	&__main {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		flex-grow: 1;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: center;
		padding-bottom: 9.2rem;

		@include over-lg() {
			padding-bottom: 11.4rem;
		}
	}

	&__footer {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		margin-bottom: 1.8rem;
		bottom: 30px;

		@include over-md() {
			align-items: center;
			flex-grow: 1;
			justify-content: flex-end;
			padding: 0;
			height: auto;
			max-height: 14.8rem;

			&._2-btns {
				max-height: 21.4rem;
			}
		}
	}

	&__title {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: .6rem;
		text-align: center;

		@include over-md() {
			margin-bottom: 2.3rem;

			h2 {

				@include over-md() {
					font-size: 4rem;
				}
			}
		}
	}

	&__icon {
		margin-right: .5rem;
	}

	&__p {
		@extend .text-2;
		font-weight: 300;
		color: $color-description;
		text-align: center;

		@include over-md() {
			margin: 0 auto;
			font-size: 3.2rem;

			&._cut {
				max-width: 52.7rem;
			}

			&._color {
				max-width: 93.4rem;
			}

			&._clarity {
				max-width: 97.4rem;
			}

			&._carat {
				max-width: 88.1rem;
			}
		}

		&._subtitle {

			@include over-md() {
				font-size: 2.4rem;
			}
		}
	}

	&__instructions {
		display: flex;
		align-items: baseline;
		justify-content: center;
		margin-top: 1rem;
		color: $color-description;
		font-size: 1.2rem;

		@include over-md() {
			font-size: 1.8rem;
		}

		.level {
			margin-left: 0.5rem;

			span {
				background-color: $color-accent;
			}
		}
	}

	&__logo {
		margin-bottom: 8.2rem;
		line-height: 0;
		text-align: center;

		@include over-md() {
			margin-bottom: 10.2rem;
		}
	}

	&__bars {
		display: flex;
		justify-content: center;
		margin-bottom: 5rem;

		.level {
			@include volume("levels", 178, 30, 168, 74);

			span {
				background-color: $color-accent;
			}
		}
	}

	&__btn {
		margin: 0 auto;
		max-width: 19.4rem;

		@include over-md() {
			max-width: 40rem;

			&:not(:first-child) {
				margin-top: 2.8rem;
			}
		}

		&:not(:last-child) {
			margin-bottom: 1.1rem;

			@include over-md() {
				margin-bottom: 0;
			}
		}
	}

	&__footer,
	&__steps {
		position: absolute;
		left: 0;
		right: 0;
	}

	&__steps {
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		margin-top: auto;
		padding-bottom: 1.8rem;
		bottom: 0;

		button {
			@extend %button;
			width: 2.9rem;
			height: .4rem;
			border-radius: .2rem;
			background-color: $btn-bg-hover-secondary;

			&:not(:last-child) {
				margin-right: .8rem;
			}

			&._active {
				background-color: $color-accent;
			}
		}
	}

	&__gia {
		margin: 0 auto;
		padding: 2.8rem 0 2rem;
		max-width: 58.2rem;

		&-title {
			margin-bottom: 2.2rem;
			font-weight: 300;
			font-size: 2.4rem;
			line-height: 29/24+em;
			color: #000;

			@include over-md() {
				margin-bottom: 1.6rem;
				font-size: 4rem;
			}
		}

		&-p {
			margin-bottom: 3.3rem;
			font-weight: 300;
			font-size: 1.8rem;
			line-height: 24/18+em;
			color: #000;

			@include over-md() {
				margin-bottom: 1.6rem;
				font-size: 2.4rem;
			}
		}

		&-image {
			line-height: 0;
			text-align: center;

			img {
				max-width: 100%;
			}
		}
	}

	&__type {
		margin: 0 auto 2rem;
		padding-bottom: 1rem;

		@include over-md() {
			margin-top: 2rem;
		}
	}
}
