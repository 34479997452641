.shape-selected {
  @include row();
  @include row-flex-column();
  align-items: center;
  justify-content: center;
  width: 55%;
  height: 55%;
  border: 1px solid $color-border;
  border-radius: 50%;
  position: absolute;
  top: 22.5%;
  left: 22.5%;
  z-index: 10;

  &_pic {
    height: 75px;
    padding-bottom: .6rem;
  }

  &_img {
    height: 100%;
  }

  &_descr,
  &_progress,
  &_accent {
    padding: ($gutter / 4) 0;
    display: none; // hide from jira ticket
  }

  &_descr {
    font-size: 1.4rem;
  }

  &_progress {
    width: 70%;
  }

  &_accent {
    font-size: 1.2rem;
    font-weight: 900;
    letter-spacing: -.1px;
    color: $color-accent-secondary;
    text-transform: uppercase;

    .ico {
      font-size: 1.3rem;
    }
  }

  .label {
    font-size: 1rem;
    line-height: 1.6rem;
    height: 1.5rem;
    letter-spacing: .1rem;
  }
}
