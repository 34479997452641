.info {

  &-block {
    padding-top: 1.5 * $gutter;
    padding-bottom: 1.5 * $gutter;
    
    @include over-lg() {
      padding-top: 2 * $gutter;
      padding-bottom: 2 * $gutter;
      border-bottom: 1px solid #c1c1c1;
    }

    &_title {
      font-size: 1.8rem;
      font-weight: 700;
      color: $color-primary;
    }

    &_subtitle {
      margin-top: .5rem;
      font-weight: 300;
      color: $color-description;
      text-align: center;
    }

    &_btn {

      &.ctrl {
        color: #000;
      }
    }

    &-collapse._opened & {

      &_btn {
        display: flex;
        align-items: center;
        width: 1.4rem;
        height: 1.4rem;

        &:before {
          content: '';
          width: 1.4rem;
          height: .2rem;
          background: #000;
          margin-top: -.2rem;
        }

        &:hover {

          &:before {
            background: $btn-bg;
          }
        }

        span {
          display: none;
        }
      }
    }

    &._compare {

      .info-block {

        &_title {
          align-self: center;
          text-align: center;
        }
      }
    }

    &._details {

      .info-block {

        &_header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include over-lg() {
            display: none;
          }
        }

        &_content {

          @include over-lg() {
            display: flex;
            padding: 0;
          }
        }
      }
    }
  }
}
.common_error {
  color: #d8000c;
  background-color: #ffbaba;
  margin: 10px 40px;
  padding: 12px;
  position: absolute;
  font-size: 14px;
  top: 8%;
  left: 10%;
  opacity: .7;
  z-index: 1000;
}

.common_error__mobile {
  color: #d8000c;
  background-color: #ffbaba;
  margin: 10px 40px;
  padding: 12px;
  position: absolute;
  font-size: 14px;
  top: 75%;
  left: 0%;
  opacity: .7;
  z-index:1000;
}
