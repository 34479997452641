.footer-partners {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 3.2rem;
	background: #fff;

	@include over-md() {
		height: 5.8rem;
	}

	&__item {
		display: flex;
		align-items: center;
		height: 1.4rem;

		@include over-md() {
			height: 2.8rem;
		}

		&:not(:last-child) {
			margin-right: 2.5rem;

			@include over-md() {
				margin-right: 4rem;
			}
		}

		img {
			max-height: 100%;
		}
	}
}