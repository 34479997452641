
.register-container {
	margin-left:auto;
	margin-right:auto;
	max-width:700px;
	width:100%;
	h2 {
		margin-bottom: 2.8rem;
		font-size:2.5em;
	}
}

.forgot-container {
	margin-top:80px;
}

.register {
	padding: 2.8rem 0 0;
	margin-top:40px;
	

	&__item {

		&:not(:last-child) {
			margin-bottom: 4rem;
		}

		&._mb-0 {
			margin-bottom: 0;
		}
	}

	&__title {
		margin-bottom: 2.8rem;
	}

	&__btn {

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	&__or {
		position: relative;
		margin: .9rem 0 4.3rem;
		text-align: center;

		span {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			padding: 0 .5rem;
			background: #fff;
			font-size: 1.6rem;
			line-height: 19/16+em;
			color: $ctrl-compare-color;
			text-align: center;
		}

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			margin-top: -.1rem;
			left: 0;
			right: 0;
			height: .1rem;
			background: $ctrl-compare-color;
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}

	&__input {

		&:not(:last-child) {
			margin-bottom: 2.1rem;
		}
	}

	&__submit {
		margin-top: 6.2rem;
	}
}

.forgot-password-holder {
	padding:25px 0px;
	display:flex;
	justify-content: center;
}

.forgot-password-btn {
	font-size:1.6em;
	padding-bottom:5px;
	border-bottom:1px solid $ctrl-compare-color;
}