.page {
  padding: (2 * $gutter) 0 (4 * $gutter);

  @include over-md() {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }

  &-container {
    margin-right: -2 * $gutter;
    margin-left: -2 * $gutter;

    &.-border_top {
      border-top: 2px solid $color-border;
    }

    &.-border_bottom {
      border-bottom: 2px solid $color-border;
    }
  }
}

@import 'header';
@import 'welcome';
