.input {
	padding: 1.1rem 0;
	border-radius: 0;
	border: none;
	border-bottom: .1rem solid $ctrl-compare-color;
	outline: none;
	box-shadow: none;
	-webkit-appearance: none;
	font-weight: 300;
	font-size: 1.5rem;
	color: $product-param-title-color;
}

.input-outline {
	padding: 0 .8rem;
	height: 3.9rem;
	border-radius: .4rem;
	border: .1rem solid $ctrl-border;
	outline: none;
	box-shadow: none;
	-webkit-appearance: none;
	@extend .text-2;
	line-height: 3.9rem;
	color: $product-param-title-color;

	@include over-lg() {
		height: 6.5rem;
		font-size: 1.8rem;
		line-height: 65/18+em;

	}
}