.cut {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 3.2rem;
		min-width: 7.4rem;
		max-width: 7.4rem;
		padding: 0 .6rem;

		@include over-lg() {
			padding: 0 3.2rem;
			min-width: 18.2rem;
			max-width: 18.2rem;
		}

		@include under-lg() {
			flex: 0 1 33.333%;
			min-width: 33.333%;
			max-width: 9.4rem;

			&:first-child {
				margin-bottom: 1.5rem;
			}
		}
	}

	&._modal & {

		&__item {
			flex: 0 1 33.333%;
			min-width: 33.333%;
			max-width: 33.333%;

			&:nth-child(n+4) {
				margin-bottom: 0;
			}
		}
	}

	&._param-description & {

		&__item {
			margin: 1rem .8rem;
			padding: 0;
			min-width: 0;
			max-width: none;
		}
	}

	&__image {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		min-height: 4.6rem;

		img {
			max-width: 100%;
			@include under-md() {
				max-width: 60px;
			}
		}
	}

	&._param-description & {

		&__image {
			margin: 0 0 .4rem;
			min-height: 0;
			width: 5.4rem;
		}
	}

	&__title {
		margin-bottom: .4rem;
		font-weight: 300;
		font-size: 1.4rem;
		line-height: 12/10+em;
		color: #000;
		text-align: center;

		@include over-md() {
			margin-bottom: 1.9rem;
			font-size: 2.4rem;
		}

		&._accent {
			padding: .6rem .7rem .5rem;
			width: 8.9rem;
			border-radius: .3rem;
			background: $color-cut;
			font-weight: 600;
			font-size: 1.2rem;
			line-height: 14/12+em;
			color: #fff;
			letter-spacing: -.13px;

			b {
				font-weight: 800;
				white-space: nowrap;
			}
		}
	}

	&._modal & {

		&__title {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			min-height: 3.9rem;
			margin-bottom: .8rem;
		}
	}

	&._param-description & {

		&__title {
			margin: 0 0 .4rem;
			font-weight: 400;
			font-size: 1.1rem;
			line-height: 8/7+em;
			color: #000;
			display: flex;
			flex-direction: column;
			justify-content: center;
			min-height: 3.5rem;

			&._accent {
				color: #fff;
				width: auto;
			}
		}
	}
}
