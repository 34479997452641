@import "src/styles/scss/basic/variables";

.paramDescription {
	position: absolute;
	transform: translateY(10%);
	margin-top: .6rem;
	display: flex;
	align-items: stretch;
	width: 44rem;
	min-height: 16rem;
	border-radius: .5rem;
	background: #fff;
	box-shadow: .2rem .2rem .5rem 0 rgba(#000,.16);
	white-space: normal;
	z-index: 100;

	&Left,
	&Right {
		flex: 0 1 60%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
		padding: 1rem;
	}

	&Left {
		border-radius: .5rem 0 0 .5rem;
		background: rgba($color-cut,.5);
		flex-basis: 40%;
	}

	&:global {

		&._cut {

			:local {

				.paramDescription {

					&Left {
						background: $color-cut;
					}
				}
			}
		}

		&._color {

			:local {

				.paramDescription {

					&Left {
						background: $color-color;
					}
				}
			}
		}

		&._clarity {

			:local {

				.paramDescription {

					&Left {
						background: $color-clarity;
					}
				}
			}
		}

		&._carat {

			:local {

				.paramDescription {

					&Left {
						background: $color-carat;
					}
				}
			}
		}
	}

	&Right {
		border-radius: 0 .5rem .5rem 0;
	}

	&Header,
	&Title,
	&Subtitle,
	&Text {
		max-width: 100%;
		text-align: left;
	}

	&Header {
		display: flex;
		align-items: center;
		margin-bottom: .7rem;
	}

	&Title,
	&Subtitle {
		font-weight: 700;
		font-size: 1.6rem;
		line-height: 15/12+em;
		color: #fff;
	}

	&Subtitle {
		margin-bottom: .4rem;
	}

	&Text {
		font-size: 1.4rem;
		line-height: 1.4;
		color: #fff;
	}
}

.triangle {
	position: absolute;
	bottom: 100%;
	left: 50%;
	display: block;
	line-height: 0;
	margin-left: 2.6rem;
}

.icon {
	margin-right: .6rem;

	:global {

		.ico-DiamondSimple {

			&.-cut {
				color: #5047A6 !important;
			}

			&.-color {
				color: #1AA5D6 !important;
			}

			&.-clarity {
				color: #FFEC00 !important;
			}

			&.-carat {
				color: #C82776 !important;
			}
		}
	}
}
