.by-size {
  padding-top: 3 * $gutter;
  padding-bottom: $gutter;
  text-align: center;

  &_ct {
    font-size: 1.6rem;

    .num {
      color: $color-carat;
      font-weight: 900;
    }

    .unit {
      color: $color-note;
      font-weight: 400;
      text-transform: uppercase;
    }

  }

  &_pic {
    position: relative;
    @include row();
    @include center();
    @include middle();
    width: 9.4rem;
    height: 9.4rem;
    margin: ($gutter / 2) auto (1.5 * $gutter);

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url('/img/carat-bg@3x.png') no-repeat 0 0;
      background-size: 100%;
    }

    img {
      width: 4.6rem;
      transition: width .25s;
    }
  }

  &_estimation {
    font-size: 1rem;

    &-title {
      font-weight: 300;
      color: $color-title-components;
    }

    &-price {
      color: $color-accent;
      white-space: nowrap;
    }
  }

  &_ctrls {
    @include row();
    @include center();
    padding-top: 4 * $gutter;
    padding-bottom: $gutter;

    &-descr {
      padding-bottom: $gutter;
      font-size: 1.4rem;
      font-weight: 300;
      letter-spacing: -.15px;
      color: $color-description;
    }
  }

  &_ctrl {
    padding-right: 2 * $gutter;
    padding-left: 2 * $gutter;
  }

  &_footer {
    padding-top: $gutter / 2;

    .btn {
      max-width: 80%;
      margin: 0 auto;

      + .btn {
        margin-top: $gutter;
      }
    }
  }
}
