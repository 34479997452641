.how-it-works {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	min-height: 100vh;
	padding: 5.1rem 1.2rem 3.7rem;

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: -2rem;
		right: -2rem;
		height: .2em;
		background: $color-accent;

		@include over-md() {
			display: none;
		}
	}

	&:before {
		content: '';
		align-self: center;
		margin-bottom: .7rem;
		width: 2.3rem;
		height: .4rem;
		border-radius: .2rem;
		background: $color-accent;

		@include over-md() {
			margin-bottom: 2.9rem;
			width: 4.9rem;
		}
	}

	&__close {
		position: fixed;
		top: 1.4rem;
		right: 1.4rem;

		.ico {
			font-size: 2rem;

			@include over-md() {
				top: 3.9rem;
				right: 4.3rem;
				font-size: 3.6rem;
			}
		}
	}

	&__title {
		margin-bottom: 3.5rem;
		font-weight: 900;
		font-size: 3rem;
		line-height: 36/30+em;
		color: #000;
		text-transform: uppercase;
		text-align: center;

		@include over-md() {
			margin-bottom: 14rem;
			font-size: 5.5rem;
			line-height: 67/55+em;
		}
	}

	&__list {
		margin-bottom: 2.8rem;

		@include over-md() {
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			margin-bottom: 16rem;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		padding: 0 .5rem 1.2rem;
		border-radius: 1.5rem;
		background: #fff;
		box-shadow: 0 .3rem .6rem 0 rgba(#000,.15);

		@include over-md() {
			position: relative;
			flex: 1 1 calc(33.333% - 4rem/3);
			min-width: calc(33.333% - 4rem/3);
			max-width: calc(33.333% - 4rem/3);
			min-height: 20.6rem;
		}

		&:not(:last-child) {
			margin-bottom: 2rem;

			@include over-md() {
				margin: 0;
			}
		}
	}

	&__number {
		align-self: center;
		position: relative;
		margin-top: -.7rem;
		margin-bottom: 1.3rem;

		@include over-md() {
			position: absolute;
			top: -1.2rem;
			left: 50%;
			transform: translateX(-50%);
			margin: 0;
		}

		svg {
			display: block;
			width: 2.7rem;
			height: auto;

			@include over-md() {
				width: 4.5rem;
			}
		}

		path {
			fill: $color-accent;
		}

		span {
			position: absolute;
			top: .1rem;
			left: 50%;
			transform: translate(-50%, 0);
			@extend .text-2;
			font-weight: 700;
			color: #fff;

			@include over-md() {
				top: .3rem;
				font-size: 1.8rem;
				line-height: 22/18+em;
			}
		}
	}

	&__icon {
		align-self: center;
		margin-bottom: .8rem;

		@include over-md() {
			display: flex;
			align-items: center;
			margin: 3.6rem 0 1.7rem;
			min-height: 7.4rem;
			line-height: 0;

			svg {
				width: 100%;
				height: auto;
			}
		}
	}

	&__item {

		&:nth-child(1) {

			.how-it-works__icon {

				@include over-md() {
					width: 6.5rem;
				}
			}
		}

		&:nth-child(2) {

			.how-it-works__icon {

				@include over-md() {
					width: 5.1rem;
				}
			}
		}

		&:nth-child(3) {

			.how-it-works__icon {

				@include over-md() {
					width: 5rem;
				}
			}
		}
	}

	&__p {
		font-weight: 100;
		@extend .text-2;
		text-align: center;

		@include over-md() {
			font-size: 1.8rem;
			line-height: 22/18+em;
		}
	}

	&__btn {

		@include over-md() {
			align-self: center;
			width: 30rem;
		}
	}
}
