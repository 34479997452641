@import "../../basic/colors.scss";

.options__container hr {
  border: none;
  border-top: 2px solid rgba(158, 158, 158, 0.2);
}

.header-builder-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.ring-name-builder {
  padding-bottom: 10px;
  font-size: 24pt;
  border-bottom: 2px solid #50e3c2;
  line-height: 46px;
  font-weight: bold;
}
.ring-brand-builder {
  font-size: 14pt;
  font-weight: lighter;
  padding-top: 10px;
  min-height: 32px;
}
.ring-info-builder {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ring-builder-trust-list {
  display: flex;
  align-items: stretch; /* Default */
  justify-content: space-between;
  justify-items: center;
  width: 100%;
  margin: 0;
  padding-left: 1px;

  .ring-build-trust-list-item {
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
    margin-right: 7px;

    span {
      font-size: 13pt;
      margin-left: 10px;
      margin-bottom: -5px;
    }
  }
}
.ring-builder-price-text {
  font-size: 16pt;
  font-weight: lighter;
  color: grey;

  span {
    color: #50e3c2;
    font-weight: 500;
  }

  .affirm-as-low-as {
    margin: 0;
    font-size: 2rem;
    margin: 20px auto;
  }
}
.ring-builder-trust-list-container {
  padding-top: 10px;
}
.ring-builder-item-code-text {
  padding-top: 4px;
  font-size: 10pt;
  font-weight: lighter;
  color: grey;
}
.ring-price-item-code-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 25px;

}

.ring-builder-categories-buttons {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  
  @include under-lg() {
    justify-content: space-around;
  }
}
.ring-builder-ring-colors-buttons {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.ring-builder-ring-metal-buttons {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  
  p {
    font-size: 16pt;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
.ring-builder-ring-colors-buttons {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  p {
    font-size: 16pt;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
.ring-builder-metal-item-button {
  font-size: 14pt;
  padding-top: 10px;
  cursor: pointer;
  padding-bottom: 10px;
  color: black;
  background-color: white;
  border-radius: 15px;
  border: 1px solid rgba(158, 158, 158, 0.2);
  margin-bottom: 10px;
  text-align: left;
  &.active-button {
    background-color: #50e3c2;
    color: white;
    outline: none;
  }
}

.ring-builder-ring-metal-img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: 5px;
  margin-bottom: -5px;
  border-radius: 100px;
}

.ring-builder-color-item-button {
  font-size: 14pt;
  padding-top: 10px;
  padding-bottom: 10px;
  color: black;
  background-color: white;
  border-radius: 15px;
  border: 1px solid rgba(158, 158, 158, 0.2);
  margin-bottom: 10px;
  text-align: left;
  cursor: pointer;
  &.active-button {
    outline: none;
    background-color: #50e3c2;
    color: white;
  }
  span.rose {
    width: 20px;
    height: 20px;
    background-color: #ffbfbf;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 5px;
    margin-bottom: -5px;
    display: inline-block;
  }
  span.yellow {
    width: 20px;
    height: 20px;
    background-color: #dcc134;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 5px;
    margin-bottom: -5px;
    display: inline-block;
  }
  span.gold {
    width: 20px;
    height: 20px;
    background-color: #dcc134;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 5px;
    margin-bottom: -5px;
    display: inline-block;
  }
  span.white {
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 5px;
    margin-bottom: -5px;
    display: inline-block;
  }
}

.ring-builder-color-item-button.rose {
  border: 1px solid #ffbfbf;
}

.ring-img-green-check {
  width: 17px;
  height: 17px;
}
.ring-consulting-container {
  display: flex;
  width: 100%;
  margin-top: 6%;
  justify-content: center;
}
.ring-consulting-messenger {
  display: flex;
  flex-direction: row;
  font-size: 16pt;
  padding-bottom: 20px;
  img {
    width: 40px;
    height: 40px;
  }
}
.ring-consulting-img-container {
  padding-right: 10px;
}
.ring-consulting-book {
  display: flex;
  flex-direction: row;
  font-size: 16pt;
  font-weight: bold;
  img {
    width: 40px;
    height: 40px;
  }
}
.ring-consulting-image-ring {
  margin-left: -180px;
  z-index: -1;
  img {
    width: 600px;
    height: 400px;
  }
}

.ring-consulting-subcontainer {
  margin-top: 125px;
}

.ring-the-perfect-ring-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}
.ring-the-perfect-ring-text {
  font-size: 16pt;
  text-align: center;
  padding-bottom: 20px;
}
.ring-whats-next-container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 75px;
}
.ring-whats-next-title {
  font-size: 20pt;
  font-weight: bold;
}
.ring-whats-next-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-items: center;
  margin-top: 35px;
  margin-bottom: 20em;
}
.ring-whats-next-info-text {
  width: 20%;
  overflow-wrap: break-word;
  text-align: center;
  font-weight: lighter;
  font-size: 14pt;
}
.ring-accordion-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6%;

  @include under-lg() {
    flex-direction: column;
    align-items: center;
    margin: 0 0 25px;
  }
}

.ring-accordion-img-container {
  width: 42%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;

  @include under-lg() {
    order: 1
  }
}
.ring-accordion-subcontainer {
  width: 50%;
  margin-top: 70px;
  margin-left: 40px;

  .diamond-details table {
    width: 100%;
  }

  @include under-lg() {
    margin: 0;
    width: 100%;
    order: 2;
  }
}

.no-border-bottom {
  border-bottom: none;
}

.ring-footer__ring-detail {
  display: inline-block;
  padding: 0 5px;

  &.color {
    width: 200px;
  }
}

.ring-footer__ring-information {
  font-size: 18pt;
  text-transform: capitalize;

  span {
    color: $progress-color;
  }
}

.borderTest {
  border: 1px solid red;
}

.ring-footer-button__icon {
  margin-left: 10%;
}

.product-page {
  position: relative;
  height: 100%;
  background-color: #fff;
}

.ring-slider-container {
  .slider-loader-container {
    display: grid;
    place-items: center;
    height: 100%;
  }
  .slick-slider {
    box-shadow: 0px 15px 8px rgba(0, 0, 0, 0.0464221);
    padding-bottom: 0;
    height: 381px;
  }
}
.slick-pic-custom .slider-images .slider-pic > img {
  max-height: 420px;
}
.mobile-footer-subcontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.diamond-detail-container-builder {
  margin-bottom: 20px;
}
a.anchor-book-virtual-consultation {
  &:hover {
    color: #4d4d4d;
  }
}

.product__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.options__container {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.footer-mobile-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 70px;
}

.btn.select-ring-form {
  &:disabled {
    border: none;
  }
  &:not(:disabled)::after {
    content: "";
    display: inline-block;
    background-image: url("/img/ring-bag.png");
    background-size: 24px;
    background-repeat: no-repeat;
    width: 5px;
    vertical-align: middle;
    color: white;
    height: 5px;
    border: none;
    padding: 16px 5px 10px 30px;
    margin-left: 10px;
  }
}

.portal-children {
  display: none;
}

#mobile-content-name-sku {
  display: none;
}
#non-mobile-content-name-sku {
  display: contents;
}

@media (min-width: 1200px) and (max-width: 1320px) {
  .options__container {
    margin-left: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .topNameBrandSection {
    display: none;
  }
  #mobile-content-name-sku {
    display: contents;
  }
  #non-mobile-content-name-sku {
    display: none;
  }
  .portal-children {
    display: block;
  }
  .ring-whats-next-info-container {
    margin-bottom: 35px;
  }
  .product-page__diamond.ring-slider-container {
    width: 100%;
  }
  .ring-name-builder {
    color: black;
    font-size: 16pt;
    line-height: 1;
  }
  .ring-price-item-code-container {
    line-height: 50px;
    margin-top: 50px;
  }
  .options__container {
    margin-left: 43px;
  }
  .product__container {
    flex-direction: column;
  }
  .slick-dots-container.slick-thumb {
    left: 0;
    width: 100%;
  }
  .button-buy-ring-footer {
    margin-left: 10px;
  }
}

@include under-md() {
  .topNameBrandSection {
    display: none;
  }
  #mobile-content-name-sku {
    display: contents;
  }
  #non-mobile-content-name-sku {
    display: none;
  }
  .portal-children {
    display: block;
  }
  .ring-name-builder {
    color: black;
    font-size: 16pt;
    line-height: 1;
  }
  .ring-price-item-code-container {
    line-height: 50px;
    margin-top: 50px;
  }
  .product__container {
    flex-direction: column;
  }
  .ring-whats-next-info-container {
    margin-bottom: 8em;
  }
  .button-select-size-footer {
    margin-right: 10px;
  }
  .ring-the-perfect-ring-imgs {
    width: 100%;
    img {
      width: 100%;
    }
  }
}

@include under-sm {
  #mobile-content-name-sku {
    display: contents;
  }
  #non-mobile-content-name-sku {
    display: none;
  }
  .portal-children {
    display: block;
  }
  .button-select-size-footer {
    margin-right: 10px;
  }
  .product__container {
    flex-direction: column;
  }
  .slick-dots-container .slick-thumb {
    width: 100%;
    left: 0px;
  }

  .topNameBrandSection {
    display: none;
  }
  .infoPriceSKUItem {
    margin-top: 50px;
  }
  #headers-options-rings {
    background-color: white;
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
  }
  #diamond-selection-ring-selections {
    position: fixed;
    display: flex;
    width: 100%;
    left: 0;
    flex-direction: row;
    justify-content: center;
    background: white;
    z-index: 9999;
  }
  .diamond-detail-container-builder {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    z-index: -1;
    top: 10px;
    margin-bottom: 20px;
  }
  #img-ringplaceholder {
    width: 250px;
    height: 250px;
  }
  .ring-consulting-image-ring img {
    width: 200px;
    height: 140px;
  }
  .ring-consulting-book {
    font-size: 11pt;
  }
  .ring-consulting-messenger {
    font-size: 11pt;
  }
  .ring-consulting-image-ring {
    margin-left: -60px;
    z-index: -1;
    margin-top: 40px;
  }
  .ring-name-builder {
    color: black;
    font-size: 16pt;
    line-height: 1;
  }
  .ring-price-item-code-container {
    line-height: 50px;
  }
  .button-buy-ring-footer {
    padding-left: 10px;
    width: 180px;
  }
  .ring-accordion-container {
    // flex-direction: column-reverse;
    align-content: space-between;
    margin-top: 0px;
    margin-bottom: 40px;
  }
  .ring-builder-ring-metal-buttons p {
    font-size: 14pt;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .ring-builder-ring-colors-buttons p {
    font-size: 14pt;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .ring-builder-metal-item-button {
    width: 100%;
  }
  .ring-accordion-img-container {
    width: auto;
    height: auto;
    margin-left: 0px;
  }
  .ring-accordion-subcontainer {
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;
  }
  .ring-consulting-container {
    margin-top: 28px;
    .ring-consulting-subcontainer {
      margin-top: 50px;
    }
  }
  .ring-the-perfect-ring-imgs {
    width: 380px;
    img {
      width: 100%;
    }
  }
  .ring-whats-next-info-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
    padding-left: 24px;
    padding-right: 24px;
    .ring-whats-next-info-text {
      width: 100%;
      padding-bottom: 40px;
    }
  }
  .ring-footer-button__icon {
    margin-left: 6%;
    width: 20px;
    height: 20px;
  }
  .select__mobile-footer {
    width: 155px;
  }
}

@include under-lg() {
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding-top: 16px;
    z-index: 30 !important;
  }

}

.footer-second {
  z-index: 30;
  margin-right: -25px;
  margin-left: -25px;
}

.product-page__fixed-footer-content.size {
  width: 195px;
}
.button-select-size-footer {
  width: 152px;
}

.ring-builder-info-holder {
  display: flex;
  justify-content: space-between;
  @include under-sm {
    display: block;
  }
}

.rbcol1,
.rbcol2 {
  width: 50%;
  @include under-sm {
    width: 100%;
  }
}
.rbcol2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16pt;
  img {
    width: 100%;
    height: auto;
  }
}

.rbcol1 {
  text-align: center;
  img {
    width: 100%;
    height: auto;
  }
}
.mt30 {
  margin-top: 30px;
}

.book-chat-btn {
  background: #574bce !important;
  border-color: #574bce !important;
  &:hover {
    background: #3a2eae !important;
  }
}

.iiz__hint {
  display: none !important;
}

.iiz {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
}

.slider-images {
  padding: 10px 30px;
}
