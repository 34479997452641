$font: 'proxima-nova', Arial, sans-serif;
$font-apercu: "Apercu Pro", Helvetica, Arial, sans-serif;
// Conponents sizes
$headerHeight: 54px;
$footerHeight: 100px;
$sidepanelHeaderHeight: 120px;
$sidepanelFooterHeight: 56px;

// Hijack colors

$color--border: #d7d7d7;
$color--primary: #3ee5c3;
$color--text:#737373;

// Colors
$bg-primary: #fff;
$bg-secondary: #fbfbfb;
$color-primary: #000;
$color-secondary: #ababab;
$color-content-old: #949494;
$color-inverse: #fff;
$color-link: mix($color-primary, #fff, 70%);
$color-link-hover: #00ffc6;
$color-title: #565353;
$color-title-components: #a7a7a7;
$color-description: #818181;
$color-note: #c5c5c5;
$color-accent: #50e3c2;
$color-accent-secondary: #f33b51;
$color-border: #e3e3e3;
$color-content: #565353;
$shadow: 0 8px 11px rgba(0, 0, 0, .18);

// Btns colors
$btn-bg: #50e3c2;
$btn-bg-secondary: transparent;
$btn-bg-hover: #00ffc6;
$btn-bg-hover-secondary: #5645D1;
$btn-bg-disabled: #d8d8d8;
$btn-border: #ACA0E8;
$btn-color: #fff;
$btn-color-secondary: #565353;
$btn-color-disabled: #565353;
$btn-border-disabled: #585858;

// Ctrl colors
$ctrl-color: #979797;
$ctrl-color-secondary: #ccc;
$ctrl-color-inverse: #fff;
$ctrl-color-disabled: mix($ctrl-color, #fff, 50%);
$ctrl-color-hover: #fff;
$ctrl-bg: transparent;
$ctrl-bg-hover: #00ffc6;
$ctrl-border: #979797;
$ctrl-border-disabled: mix($ctrl-border, #fff, 50%);
$ctrl-menu-color: #4c4c4c;
$ctrl-compare-color: #cbcbcb;

// Specific colors
$color-cut: #574bce;
$color-color: #00bcff;
$color-clarity: #ffd800;
$color-carat: #ff007c;

// Goods colors
$good-bg: #ffffff;

// Label colors
$label-bg: #2c2c2c;

// Progress colors
$progress-bg: #eee;
$progress-color: #50e3c2;
$progress-color-secondary: #f35669;
$progress-description-color: #464646;
$progress-name-color: #2b2b2b;

// Product colors
$product-view-color: #080808;
$product-param-title-color: #757575;

// New
$dove-gray: #686868;
