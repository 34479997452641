.select {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.8rem;
  padding-right: 2.7rem;
  width: 100%;
  height: 3.9rem;
  border-radius: 0.4rem;
  border: 0.1rem solid $ctrl-border;
  outline: none;

  @include over-lg() {
    padding-left: 1.6rem;
    padding-right: 4rem;
    height: 6.5rem;
  }

  &__placeholder {
    overflow: hidden;
    @extend .text-2;
    line-height: 3.9rem;
    color: $product-param-title-color;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include over-lg() {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 29/18 + em;
    }
  }

  &._rounded {
    padding-left: 1.3rem;
    border-radius: 2rem;
    @extend .text-2;

    &.error {
      border-color: red;

      @include over-lg() {
        border-width: 2px;
      }
    }

    @include over-lg() {
      border-radius: 3.3rem;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.8rem;
    display: block;
    width: 1.5rem;
    height: 0.8rem;
    pointer-events: none;
    background: url("/img/select-triangle.svg") no-repeat left top;
  }

  @include over-lg() {
    &:after {
      top: 3.3rem;
      right: 1.5rem;
      width: 2.3rem;
      height: 1.23rem;
      background-size: 100%;
    }
  }

  &._rounded {
    &:after {
      right: 1.6rem;
      width: 1.74rem;
      height: 0.7rem;
      background: url("/img/select/arrow-down.svg") no-repeat left top;
    }
  }

	select {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		opacity: 0;
		border-radius: 0;
	}

	&.error {
		color: red;

		select {
			border-color: red;
			border-width: 2px;
		}

	}
}
.select__placeholder {
  margin: auto;

  &.error {
    color: red;
  }

  @include under-lg() {
    padding-right: 10px;
  }
}
