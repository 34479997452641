.page {

  &-header {
    padding-top: 2 * $gutter;
    padding-bottom: 2 * $gutter;

    &.-centered {
      text-align: center;
    }

    @include over-md() {
      margin-bottom: 3.5rem;
      padding: 0;
      text-align: center;

      &.-centered {
        text-align: left;
      }
    }
  }

  &-title {
    padding-bottom: $gutter / 2;
    font-size: 1.8rem;
    font-weight: 300;
    color: $color-title;

    @include over-md() {
      margin-bottom: 1.5rem;
      padding-bottom: 0;
      font-size: 3.4rem;
    }

    &.-bold {
      font-weight: 700;
    }
  }

  &-description {
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: -.15px;
    color: $color-description;

    @include over-md() {
      max-width: 36rem;
      font-size: 2rem;
      margin: 0 auto;
    }
  }
}
