.gia_cert {
  position: relative;
  margin: 0 auto;
  border-bottom: 1px solid $color-border;
  background-color: #fff;
  cursor: pointer;
  width: 300px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  .logo-container {
    padding: 0 10px;
    border-right: 1px solid $color-border;

    &:last-child {
      border-right: none;
    }

    img {
      width: 25px;
    }
  }

  @include under-lg() {
    top: -90px;
  }

  padding: 1rem 1.7rem;
  border-radius: 1.4rem;
  background: #fff;
  box-shadow: 0 0.4rem 1.1rem 0 rgba(#000, 0.09);
  z-index: 110;
  margin-top: -3rem;
}

.gia-cert-contact-form {
  width: 350px;
  height: 500px;
  padding: 4rem 3.25rem;
  perspective: 1000px;

  &.flip-form {
    .flip {
      transform: rotateX(180deg);
    }
  }

  .flip {
    position: relative;
    width: 100%;
    height: 50%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: transform 0.8s;

    .card-front,
    .card-back {
      position: absolute;
      height: 100%;
      width: 100%;
      backface-visibility: hidden;
    }

    .card-back {
      display: grid;
      place-items: center;
      transform: rotateX(180deg);
    }
  }

  .ico.ico-Cross {
    font-size: 2rem;
  }

  img {
    display: block;
    margin: auto;
    width: 50px;
  }

  .title {
    font-size: 2.6rem;
    text-align: center;
    margin: 2rem 0;
  }

  .sub-title {
    font-size: 1.7rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 0 2rem;

    .error {
      color: red;
      text-align: center;
      font-size: 1.2rem;
    }

    input {
      margin-bottom: 1rem;
      height: 3rem;
      border: 1px solid $color-border;
      border-radius: 5px;
      box-shadow: none;
      outline: none;
    }

    button {
      align-self: center;
      padding: 8px 16px;
      border: none;
      border-radius: 5px;
      color: white;
      background-color: $btn-bg;

      &:hover {
        background-color: $btn-bg-hover;
      }
      &:disabled {
        background-color: $btn-bg-disabled;
        color: $color--text;
      }
    }
  }

  .separator {
    margin: 20px 0;
    text-align: center;

    &::after {
      content: "";
      display: block;
      position: relative;
      border-top: 1px solid $color-border;
      top: -10px;
    }

    span {
      padding: 5px;
      background-color: white;
      font-size: 1.8rem;
      position: relative;
      z-index: 1;
    }
  }

  .chat-link {
    font-size: 1.6rem;
    text-decoration: underline;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
  }

  .gia-cert-modal_close {
    position: absolute;
    top: 2px;
    right: 3px;
    font-size: 1rem;
    color: $ctrl-color;
    border: none;
    background-color: white;
    padding: 10px;
  }
}
