.product-list {
  width: 100%;

  .loader-container {
    display: grid;
    place-items: center;
    min-height: 100px;
  }

  .ring_error {
    position: absolute;
    top: 100px;
    left: 0;
    color: #d8000c;
    background-color: #ffbaba;
    margin: 10px 40px;
    padding: 12px;
    font-size: 14px;
    opacity: 0.7;
    z-index: 1;
    cursor: pointer;
  }

  @include over-md() {
    position: relative;
  }

  &__buttons {
    &.buttons {
      @include over-md() {
        position: absolute;
        left: auto;
        right: 27.4rem;
        bottom: 7rem;
        padding: 0;
        width: auto;
      }
    }
  }

  &__compare {
    &.btn {
      &,
      &:not(:last-child) {
        @include over-md() {
          margin: 0;
          min-width: 30rem;
        }
      }
    }
  }

  &__cancel {
    @include over-md() {
      display: none;
    }
  }
}
