.content {
  font-size: 1.4rem;
  font-weight: 300;
  color: $color-content;

  p + p {
    padding-top: $gutter / 2;
    font-size: inherit;
  }

  ul {
    padding-top: 1.5 * $gutter;
    padding-bottom: 1.5 * $gutter;
    list-style-position: inside;
    font-size: inherit;

    > li  {

      &.mark-ico {
        padding-left: 4 * $gutter;
        position: relative;

        &:before {
          @include icomoon();
          color: $color-accent;
          position: absolute;
          left: 0;
        }

        &.-Check2 {
          &:before {
            content: $ico-Check2;
            font-size: 3rem;
            top: 0;
          }
        }

        &.-Leaf {
          &:before {
            content: $ico-Leaf;
            font-size: 3.9rem;
            top: -.5rem;
          }
        }

        + li {
          margin-top: 2 * $gutter;
        }
      }

      + li {
        margin-top: 1.5 * $gutter;
      }
    }

    &.-nomarks {
      list-style: none;
    }
  }
}
