// Grid
$grid-columns: 12 !default; // Set number of columns in the grid

$xs-max: 319px !default; // Set xs breakpoint's max width
$sm-max: 639px !default; // Set sm breakpoint's max width
$md-max: 1099px !default; // Set md breakpoint's max width
$lg-max: 1920px !default; // Set lg breakpoint's max width

$gutter: 10px !default; // Set gutter size

$content-well-max-width: "none" !default; // Set the max-width of the content well

$sm-start: ($xs-max + 1); // Generate sm breakpoint's min width
$md-start: ($sm-max + 1); // Generate md breakpoint's min width
$lg-start: ($md-max + 1); // Generate lg breakpoint's min width
$xl-start: ($lg-max + 1); // Generate xl breakpoint's min width

// Breakpoints
// Create breakpoint range statements to be used in media queries
$breakpoint-xs-only: "only screen and (max-width: #{$xs-max})"; // 0 -> xs-max range
$breakpoint-sm-up: "only screen and (min-width: #{$sm-start})"; // sm-start -> up range
$breakpoint-sm-only: "only screen and (min-width: #{$sm-start}) and (max-width: #{$sm-max})"; // sm-start -> sm-max range
$breakpoint-md-up: "only screen and (min-width: #{$md-start})"; // md-start -> up range
$breakpoint-md-only: "only screen and (min-width: #{$md-start}) and (max-width: #{$md-max})"; // md-start -> md-max range
$breakpoint-lg-up: "only screen and (min-width: #{$lg-start})"; // lg-start -> up range
$breakpoint-lg-only: "only screen and (min-width: #{$lg-start}) and (max-width: #{$lg-max})"; // lg-start -> lg-max range
$breakpoint-xl-up: "only screen and (min-width: #{$xl-start})"; // xl-start -> up range

$breakpoints-all: ($breakpoint-xs-only, $breakpoint-sm-up, $breakpoint-sm-only, $breakpoint-md-up, $breakpoint-md-only, $breakpoint-lg-up, $breakpoint-lg-only, $breakpoint-xl-up);
$breakpoint-ups: ($breakpoint-sm-up, $breakpoint-md-up, $breakpoint-lg-up, $breakpoint-xl-up);
$breakpoint-all-prefixes: ("xs", "xs-only", "sm", "sm-only", "md", "md-only", "lg", "lg-only", "xl");
$breakpoint-up-prefixes: ("xs", "sm", "md", "lg", "xl");

// ALTERNATIVE VARIABLES
// Screens
$xxxlg: 1920;
$xxlg: 1440;
$xlg: 1280;
$lg: 1200;
$md: 992;
$sm: 768;
$xs: 320;