$icomoon-font-family: 'Joolez_font' !default;
$icomoon-font-path: '/fonts/Joolez_font' !default;

$ico-Check: "\e918";
$ico-Check2: "\e919";
$ico-Menu: "\e917";
$ico-Minus: "\e904";
$ico-Plus: "\e905";
$ico-ArrowLeft: "\e900";
$ico-ArrowTop: "\e901";
$ico-ArrowRight: "\e902";
$ico-ArrowBottom: "\e903";
$ico-Cross: "\e906";
$ico-AcademicCap: "\e907";
$ico-ArrowBack: "\e911";
$ico-Bag: "\e908";
$ico-BagDiamond: "\e909";
$ico-Charge: "\e90a";
$ico-Diamond: "\e90b";
$ico-DiamondQuestion: "\e90c";
$ico-Diamonds: "\e90d";
$ico-DiamondSimple: "\e90e";
$ico-Fire: "\e90f";
$ico-HandShake: "\e910";
$ico-Leaf: "\e912";
$ico-MicroChip: "\e913";
$ico-Ribbon: "\e914";
$ico-Ring: "\e915";
$ico-Ring2: "\e916";
