@import "../../basic/variables";
@import "../volume";

.footer {
  padding-top: 4 * $gutter;
  padding-bottom: 1.5 * $gutter;

  @include over-lg() {
    position: fixed;
    top: 9.5rem;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  .centered-block {
    @include row();
    @include middle();
    @include around();

    &.-icons {
      @include center();
    }

    @include over-lg() {
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      min-width: 0;
      max-height: 35.3rem;
      padding-left: 6.5rem;
      padding-right: 4rem;
      width: 16.5rem;
    }

    @media (min-width: 1840px) {
      padding-left: 8.5rem;
      width: 18.5rem;
    }
  }

  .ctrl {
    display: block;
    color: $ctrl-color-secondary;
  }

  &-ico {
    max-height: 12px;
    padding-right: $gutter;
    padding-left: $gutter;
  }

  &.-bg {
    background: $bg-primary;
  }

  &.-sm {
    padding-top: $gutter / 2;
    padding-bottom: $gutter / 2;
  }

  &__link {

    @include over-lg() {

      &:nth-child(1) {

        .ico {
          font-size: 4.9rem;
        }
      }

      &:nth-child(2) {

        .ico {
          font-size: 4.7rem;
        }
      }

      &:nth-child(3) {

        .ico {
          font-size: 4rem;
        }
      }
    }

    .level {
      @include volume("level", 25, 4, 22, 10);

      @include over-lg() {
        @include volume("level", 45, 7, 40, 20);
      }

      span {
        background-color: $ctrl-color-secondary;
      }
    }

    &:hover {
      .level span {
        background-color: $color-link-hover;
      }
    }
  }

  .ico {

    &.active {
      color: $color-link-hover;
    }
  }

  .ctrl.-lg .ico {
    @include over-lg() {
      font-size: 5rem;
    }
  }
}

/* ***************
 * FB on left side 
 ******************/
/* this is for the circle position */
.fb_dialog.fb_dialog_advanced {
    left: 18pt !important;
}

/* The following are for the chat box, on display and on hide */
iframe.fb_customer_chat_bounce_in_v2 {
    left: 9pt !important;
}
iframe.fb_customer_chat_bounce_out_v2 {
    left: 9pt !important;
}


.fb_dialog_content {
  display: none;
}


.fb_iframe_widget iframe {
  @include over-lg() {
    left: 18pt !important;
  }
}