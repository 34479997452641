.buttons {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;

	&._fixed {
		position: fixed;
		bottom: 6.4rem;
		left: 0;
		right: 0;
		padding: 0 2rem;
	}

	&._comparison {
		display: flex;
		flex-direction: column;
		align-items: center;

		.btn {
			width: 19.4rem;
		}
	}

	.btn {

		&:not(:last-child) {
			margin-bottom: 1.8rem;
		}
	}
}