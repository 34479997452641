#diamond-video-iframe {
  width: 80vw;
  height: 90vh;
  max-height: 600px;
  border: 1px solid lightgray;
  border-radius: 0.8rem;
  
  @include over-lg() {
    max-height: 925px;
  }
}

.slider {
  &.modal-open {
    z-index: 111;
  }

  .video360-icon {
    height: 4rem;
    width: 4rem;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    color: #222222;
    cursor: pointer;
  }
}

.video360-modal {
  padding: 45px 20px;
  
  @include over-lg() {
    padding: 50px;
  }

  &--close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
  }
}

