.form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0.9rem 0 0;
  width: 100%;

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;

    &-checkbox {
      margin-left: 2rem;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    
    @include under-sm() {
      flex-wrap: wrap;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;

      @include over-md() {
        margin-bottom: 2.8rem;
      }
    }
  }

  &__item {
    &._fname {
      @include under-lg() {
        flex: 1 1 100%;
        margin-bottom: 10px;
      }

      @include over-sm() {
        margin-right: 20px;
      }
    }
    &._lname {
      @include under-lg() {
        flex: 1 1 100%;
      }
    }

    &._address {
      flex: 0 1 181/261 * 100%;
      min-width: 181/261 * 100%;
      max-width: 181/261 * 100%;
    }

    &._apt {
      margin-left: auto;
      flex: 0 1 65/261 * 100%;
      min-width: 65/261 * 100%;
      max-width: 65/261 * 100%;
    }

    &._phone {
      flex: 0 1 149/261 * 100%;
      min-width: 149/261 * 100%;
      max-width: 149/261 * 100%;
    }

    &._zip {
      margin-left: auto;
      flex: 0 1 95/261 * 100%;
      min-width: 95/261 * 100%;
      max-width: 95/261 * 100%;

      &-1 {
        margin-left: auto;
        flex: 0 1 124/261 * 100%;
        min-width: 124/261 * 100%;
        max-width: 124/261 * 100%;
      }
    }

    &._country,
    &._city {
      flex: 0 1 149/261 * 100%;
      min-width: 149/261 * 100%;
      max-width: 149/261 * 100%;
    }

    &._state {
      margin-left: auto;
      flex: 0 1 95/261 * 100%;
      min-width: 95/261 * 100%;
      max-width: 95/261 * 100%;

      &-1 {
        flex: 0 1 124/261 * 100%;
        min-width: 124/261 * 100%;
        max-width: 124/261 * 100%;
      }

      .label-container {
        display: flex;
        align-items: center;

        label::after {
          content: "*";
          color: red;
          margin-left: 2px;
        }
      }

      &.error {
        .label-container,
        label,
        .select__placeholder {
          color: red;
        }
        .label-container > span {
          margin-bottom: 1rem;
          font-size: 1.5rem;
          font-weight: 600;

          @include under-md() {
            font-size: 1.2rem;
          }
        }
        .select {
          border-color: red;
          border-width: 0.2rem;
        }
      }
    }
  }

  &__p {
    a {
      color: $color-accent;
      text-decoration: none;
    }
  }

  &__buttons {
    margin-top: auto;
    padding-top: 2rem;
  }
}
