.ring-card-top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ring-card-top-label {
    width: 35%;
    height: auto;
    background: black;
    color: white;
    font-size: 11pt;
    border-radius: 100px;
    text-align: center;
    line-height: 21px;
}
.ring-card-top-icon {
    padding-right: 10px;
}