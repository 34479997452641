.carat {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	max-width: 16.2rem;

	@include over-md() {
		justify-content: center;
		max-width: none;
	}

	&__item {
		flex: 0 0 8.1rem;
		max-width: 8.1rem;
		min-width: 8.1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 3.8rem;
		padding: 0 1.2rem;
		font-weight: 700;
		font-size: 1rem;
		line-height: 12/10+em;
		color: #000;

		@include over-md() {
			flex: 0 0 20rem;
			max-width: 20rem;
			min-width: 20rem;
			padding: 0 3.6rem;
			font-size: 2.4em;
			line-height: 2.9rem;
		}
	}

	&._param-description & {

		&__item {
			flex: 0 1 50%;
			margin: 0;
			padding: .5rem;
			min-width: 0;
			max-width: none;
			font-size: 1.3rem;
			line-height: 12/10+em;
		}
	}

	&__image {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		margin-bottom: 1rem;
		min-height: 5.8rem;
		line-height: 0;

		img {
			max-width: 100%;
		}

		@include over-md() {
			align-items: center;
			min-height: 13.2rem;
		}
	}

	&._param-description & {

		&__image {
			margin-bottom: 1rem;
			width: 7rem;
			min-height: 5rem;
		}
	}
}
