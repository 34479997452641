@import "src/styles/scss/basic/grids/variables";
@import "src/styles/scss/basic/variables";
@import "src/styles/scss/basic/mixins";

.section {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	text-align: center;
}

.title {
	font-weight: 700;
	font-size: 3rem;
	line-height: 36/30+em;
	color: #000;
	text-transform: uppercase;
}

.subtitle {
	margin-bottom: 5rem;
	font-weight: 400;
	font-size: 2.4rem;
	line-height: 36/24+em;
	color: #000;
	text-transform: uppercase;
}

.icon {
	align-self: center;
	display: flex;
	justify-content: center;
	line-height: 0;

	svg {
		width: 100%;
		height: auto;
	}

	&._1 {
		max-width: 13rem;
	}

	&._2 {
		margin-bottom: 6rem;
		max-width: 10rem;
	}

	&Text {
		margin-bottom: 4rem;
		font-size: 1.4rem;
		line-height: 36/14+em;
		color: $color-note;
	}
}

.p {
	margin-bottom: 1.6rem;
	font-size: 1.6rem;
	line-height: 36/16+em;
	color: #000;
}

.btn {
	align-self: center;
	margin-bottom: 6rem;
	max-width: 25rem;
}

.social {
	display: flex;
	flex-direction: column;
	align-items: center;

	&Title {
		font-weight: 400;
		font-size: 1.6rem;
		line-height: 36/14+em;
		color: $color-note;
	}

	&List {
		display: flex;
		align-items: center;
	}

	&Link {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: .4rem;
		width: 3.2rem;
		height: 3.2rem;
		border-radius: 50%;
		background: $color-note;

		

		&:not(:last-child) {
			margin-right: 1.6rem;
		}

		&.menu {
			margin-right: 0.2rem;
		}

		svg {
			width: 100%;
			height: auto;
		}

		path,
		circle {
			fill: #fff;
		}
	}
}

.menu {
	margin-right: 0;
	height: 100%;
	width: 3rem;
}