.affirm-as-low-as {
  > a {
    pointer-events: none;
  }

  &.param-section-affirm {
    font-size: 1.5rem;
    margin-right: 10px;

    @include under-lg() {
      display: none;
    }
  }

  &.product-detail-mobile-affirm {
    font-size: 2rem;
    margin: 25px auto;
    width: fit-content;
    min-height: 50px;

    .affirm-ala-price, .per-month {
      color: $color-accent;
      font-size: 2.2rem
    }

    @include over-lg() {
      display: none;
    }
  }

  &.product-detail-footer-affirm {
    font-size: 1.8rem;

    .affirm-ala-price, .per-month {
      color: $color-accent;
      font-size: 4rem;
      font-weight: 700
    }
  }
}

.cart-affirm {
  display: flex;
  justify-content: flex-end;
  position: relative;

  .price-separator {
    color: $color--text;
    background-color: white;
    font-size: 1.5rem;
    position: absolute;
    top: -4px;
    right: 70px;
    line-height: 0.4;
    padding: 0 10px;
    
    @include over-lg() {
      top: 0;
      line-height: 0.5;
      right: 105px;
      font-size: 2rem;
    }
  }


  .affirm-as-low-as {
    width: fit-content;
    padding-top: 10px;
    border-top: 1px solid lightgray;

    .affirm-ala-price, .per-month {
      color: $color-link-hover;
    }
  
    @include over-lg() {
      font-size: 2.2rem;
    
      .affirm-ala-price {
        font-size: 4.2rem;
      }
    }
  }
}

.product-params {
  padding-top: 1.6rem;
  padding-bottom: 3.2rem;
  border-bottom: 2px solid $color-border;
  
  @include over-lg() {
    position: relative;
    padding: 4.2rem 9rem 4.2rem 0;
    border-bottom: none;
  }

  &_footer {
    @extend %row-width;

    &-col {
      @include col(6);
    }
  }

  &_buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1.3rem;

    & > button {
      flex: 1 1 calc(50% - 0.5rem);
      max-width: calc(50% - 0.5rem);
      min-width: calc(50% - 0.5rem);

      &.-active {
        border-width: 0 !important;
        background-color: $btn-bg !important;
        color: #fff !important;
      }

      &.visible-desktop {
        @include under-lg {
          display: none;
        }
      }
    }

    &.center-mobile {
      @include under-lg {
        justify-content: center;
      }
    }
  }

  &__right-buttons {
    position: absolute;
    top: 11rem;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 8rem;

    @include under-lg() {
      display: none;
    }
  }

  &__right-btn {
    @extend %button;
    width: auto;
    height: 3.2rem;

    path {
      fill: $ctrl-color;
    }

    &:not(:last-child) {
      margin-bottom: 3.2rem;
    }

    &.-compare {
      svg {
        width: 3.8rem;
      }

      path {
        fill: $ctrl-color;
      }
    }
  }
}

.product-param {
  @include shakeAnimation();

  &:first-child {
    margin-bottom: 1.5rem;
  }

  &:nth-child(2) {
    .progress-bar_description {
      padding-bottom: $gutter / 2;
    }
  }

  &_header {
    @include row();
    @include between();
    @include middle();
    padding-bottom: $gutter / 2;
  }

  &_name,
  &_title,
  &_loader {
    color: $product-param-title-color;
    font-size: 2rem;

    .loader {
      height: 2rem;
      margin-left: 20px;
    }
  }

  &_name {
    margin: 4rem 0 2rem;
    display: flex;
    align-items: center;

    .badge {
      font-size: 1.2rem;
      text-transform: capitalize;
      color: white;
      background-color: $color-accent;
      padding: 0.5rem 1rem;
      border-radius: 2rem;
      margin-left: 2rem;
      box-shadow: 0 0 8px $color-accent;
      animation: shake 1s both infinite;
    }
  }

  &_title {
    flex: 1;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
  }

  &_param {
    font-weight: 600;

    &.-price {
      color: $color-accent;
      margin-right: 10px;
    }
  }

  &_controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 135px;

    .ctrl {
      display: inline-block;

      + .ctrl {
        margin-left: 1.5 * $gutter;
      }

      &.-bordered {
        width: 2.6rem;
        height: 2.6rem;

        .ico {
          line-height: 2.6rem;
        }
      }

      .ico {
        font-size: 1.3rem;
      }
    }

    .control-buttons-title {
      font-size: 2rem;
      font-weight: 300;
      color: $product-param-title-color;
      margin: 0 5px;
    }
  }

  &_ico {
    font-size: 1.6rem;

    &.-carat {
      color: $color-carat;
    }
    &.-cut {
      color: $color-cut;
    }
    &.-clarity {
      color: $color-clarity;
    }
    &.-color {
      color: $color-color;
    }
  }
}
