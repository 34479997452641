.slider {
  position: relative;
  @extend %row-width;
  height: 300px;
  margin-bottom: 2 * $gutter;
  background: $bg-primary;
  border-radius: 16px;
  box-shadow: 0 15px 8px 0 rgba(0, 0, 0, .05);

  @include over-lg() {
    height: 250px;
  }

  &-images {
    width: 100%;
    height: 100%;
    padding: $gutter (5 * $gutter);
    overflow: hidden;
    border-radius: 16px;
    position: relative;
  }

  &-pic {
    @include row();
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &-img {
    max-width: 80%;
    max-height: 80%;
  }

  &-ctrl {
    position: absolute;
    top: calc(50% - 15px);

    &.-prev {
      left: $gutter;
    }

    &.-next {
      right: $gutter;
    }
  }

  &-labels {
    position: absolute;
    z-index: 1;
    top: 1rem;
    left: 1rem;
    display: flex;
    align-items: center;
  }

  &-label {

    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
}
