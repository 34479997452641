.label {
  @include textOverflow(18px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 (1.5 * $gutter);
  border: 0;
  border-radius: 12px;
  outline: none;
  font-size: 1.2rem;
  font-weight: 900;
  letter-spacing: .1px;
  text-align: center;
  text-transform: uppercase;
  background: $label-bg;
  color: $color-inverse;

  @include over-md() {
    min-width: 9.4rem;
    height: 2.3rem;
  }

  &.-green {
    background: $color-accent;
  }

  &.-rose {
    background: $color-carat;
  }

  &.-sm {
    @include textOverflow(12px);
    padding: 0 $gutter;
    font-size: .8rem;
    line-height: 13px;
  }

  &.-md {
    @include textOverflow(2.1rem);
    padding: 0 1rem;
    border-radius: 1.1rem;
    font-size: .8rem;
    line-height: 1.15;
    letter-spacing: -.009rem;
  }

  &.-lg {
    @include textOverflow(21px);
    border-radius: 30px;
  }

  &.-order {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 17/14+em;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: -.015em;
    text-align: center;
    white-space: nowrap;
  }

  &._cu-p {
    cursor: pointer;
  }
}
