.logo {
  display: block;
  color: $color-link;
  text-decoration: none;
  border: 0;
  outline: none;

  &-text {
    font-size: 2.4rem;
    font-weight: 700;
  }

  &-ico {
    position: relative;
    margin-left: $gutter / 2;
    font-size: 2rem;

    @include over-md() {
      font-size: 3rem;
      margin-left: $gutter;
    }

    .cart-count {
      position: absolute;
      left: 0;
      right: 0;
      top: 45%;
      font-size: 1.2rem;
      font-weight: bold;
      width: 100%;
      text-align: center;

      @include over-md() {
        font-size: 1.5rem;
      }
    }
  }

  &:hover {
    color: $color-link-hover;
  }

  &.-inverse {
    color: $color-inverse;

    &:hover {
      color: $color-link-hover;
    }

    &:focus {
      color: $color-inverse;
    }
  }
}
