@import 'logo';
@import 'filter';

.header {
  width: 100%;
  min-width: $sm-start;
  background: $bg-primary;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 111;

  .centered-block {
    @include row();
    @include middle();
    @include between();
    height: $headerHeight;

    @include over-md() {
      padding-top: 2.2rem;
      padding-bottom: 2.2rem;
      height: auto;
    }
  }

  &.-nobg {
    background: transparent;
  }

  .logo-text {

    @include over-md() {
      font-weight: 700;
      font-size: 4.2rem;
    }
  }

  .ctrl.-lg {

    .ico-Menu {

      @include over-md() {
        font-size: 4rem;
      }
    }
  }

  &__comparison {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1.5rem;
    margin-right: auto;
    height: 3.6rem;
    padding: 0 1.5rem;
    border-radius: 1.8rem;
    background: $btn-bg ;
    font-weight: 900;
    font-size: 2.5rem;
    line-height: 1.15;
    color: #fff;

    @include under-md() {
      display: none;
    }
  }
}

.-index {

  .header {

    .ico-Menu {
      color: #fff;
    }
  }
}
