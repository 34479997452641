@import "src/styles/scss/basic/grids/variables";
@import "src/styles/scss/basic/variables";
@import "src/styles/scss/basic/mixins";
// @import 'src/styles/scss/basic/fonts/Joolez_font/apercu';
$space-xs:    0.5em;
$space-sm:    0.8em;
$space-md:    1.25em;

.dr-nav-holder {
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content:space-between;
}
.dr-nav {
    padding:$space-md;
    margin-top:80px;
    font-family:$font-apercu;
    
    ul {
        list-style:none;
        
        
    }

    .border-top {
        padding-top:$space-md;
        border-top:1px solid $color--border;
        margin-top:$space-md;
    }
}
.dr-nav__link {
    font-family:$font-apercu;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    padding: $space-xs $space-md;
    color: $color--text;
    transition: .3s;
    font-size:24px;

    span:first-child {
        flex: 1;
    }

    :nth-child(2)::before {
      content: '';
      display: inline-block;
      height: 1px;
      width: 32px;
      margin-right: 20px;
      background-color: currentColor;
      vertical-align: middle;
      transform: scaleX(0);
      transform-origin: right center;
      transition: transform .3s;
    }
    &.dr-nav__link-primary {
        
    }
    &.dr-nav__link-small {
       font-size:20px;
       padding-left:1.5em;
    }
    &:hover {
      color: $color--primary;
  
      :nth-child(2)::before {
        transform: scaleX(1);
      }
    }
  }

.dr-nav-footer {
    display:flex;
    justify-content:center;
    align-items:center;
}

.dr-nav-footer-icon-link {
    display:block;
    width:45px;
    height:45px;
    padding:10px;
    svg {
        width: 100%;
        height: auto;
    }

    path,
    circle {
        fill: #666;
    }
    &:hover {
        path,
        circle {
            fill: $color--primary;
        }
    }
}