$breakpoint-tablet: 768px;
.ring-slider--detail {
	-webkit-box-shadow: -6px 2px 14px -7px rgba(194, 192, 194, 1);
	-moz-box-shadow: -6px 2px 14px -7px rgba(194, 192, 194, 1);
	box-shadow: -6px 2px 14px -7px rgba(194, 192, 194, 1);
	height: 500px;
	position: relative;
	border-radius: 8em;
}
.slider-custom {
	width: 634px;
	height: 381px;
}
// image container
.image-container {
	display: flex;
	width: 80em;
	height: 400px;
	overflow: hidden;
	margin: 0 auto;
}
.image-container img {
	width: 565px;
	transition: transform 0.4s ease-in-out;
	margin: 0 auto;
	border-radius: 22px;
}
.slider-main-container {
	position: relative;
	padding-left: 10%;
	display: flex;
	img {
		width: 420px;
		height: 420px;
	}
}
@media (max-width: $breakpoint-tablet) {
	.slider-main-container {
		// width: 100%;
		// height: 400px;
	}
}
.ring-label__container {
	width: 12em;
	height: 2.5em;
	padding: 0.8em 1.5em 1.1em 1.5em;
	background-color: #383838;
	border-radius: 20px;
	position: absolute;
	top: 3%;
	left: 2%;
}
.ring-label__name {
	color: #fff;
	text-align: center;
	font-weight: bold;
	font-size: 14pt;
    line-height: 10px;
}
.slider-arrow__right {
	position: absolute;
	right: 5%;
	top: 50%;
}
.slider-arrow__right img {
	position: absolute;
	right: 5%;
	top: 45%;
	cursor: pointer;
	width: 20px;
	height: 30px;
}
.slider-arrow__left {
	position: absolute;
	left: 5%;
	top: 50%;
}
.slider-arrow__left img {
	position: absolute;
	right: 5%;
	top: 45%;
	cursor: pointer;
	width: 20px;
	height: 30px;
}
.ring-image-current {
	width: 50em;
	height: 400px;
	z-index: 0;
	position: absolute;
	-webkit-box-shadow: 0px 5px 13px 6px rgba(219, 216, 219, 1);
	-moz-box-shadow: 0px 5px 13px 6px rgba(219, 216, 219, 1);
	box-shadow: 0px 5px 13px 6px rgba(219, 216, 219, 1);
	border-radius: 10px;
}
/* thumbnails section */
.thumbnail-container {
	width: 100%;
	height: 73px;
	position: absolute;
	background-color: #fff;
	top: 90%;
	left: 2%;
	border-radius: 14px;
	-webkit-box-shadow: 0px 5px 14px -2px rgba(219, 216, 219, 1);
	-moz-box-shadow: 0px 5px 14px -2px rgba(219, 216, 219, 1);
	box-shadow: 0px 5px 14px -2px rgba(219, 216, 219, 1);
	padding: 5px 5%;
}
.thumbnail-inner-container {
	display: flex;
	flex-direction: row;
	img {
		width: 80px;
		height: 69px;
		overflow-y: scroll;
		cursor: pointer;
	}
}

.thumbnail-current__border {
	border: 2px solid #50e3c2;
	border-radius: 4px;
}