.btn {
  display: block;
  width: 100%;
  height: 44px;
  padding: 0 (1.5 * $gutter);
  border-radius: 22px;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: -.2px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  @include over-lg() {
    display: inline-flex;
    vertical-align: top;
    justify-content: center;
    align-items: center;
    width: auto;
    min-width: 40rem;
    height: 6.6rem;
    border-radius: 3.3rem;
    font-size: 2.4rem;

    &.-width-sm {
      min-width: 30rem;
    }

    &.-width-xs {
      min-width: 12rem;
    }
  }

  &:not(:disabled) {
    border: 1px solid $btn-bg;
    background: $btn-bg;
    color: $btn-color;
    cursor: pointer;
    &:hover {
      background: $btn-bg-hover;
      border-color: $btn-bg-hover;
      outline: none;
    }
  }

  &:disabled {
    background: $btn-bg-disabled;
    color: $btn-color-disabled;
    border: 1px solid $btn-border-disabled;
    cursor: default;
  }

  &.-secondary {
    &:not(:disabled) {
      border: 1px solid $btn-border;
      background: $btn-bg-secondary;
      font-weight: 300;
      color: $btn-color-secondary;
      &:hover {
        background: $btn-bg-hover-secondary;
        color: #fff;
      }
    }
  }

  &.-white {
    &:not(:disabled) {
      border: none;
      background: $btn-color;
      color: $btn-bg;
      &:hover {
        background: $btn-bg-hover;
        color: $btn-color;
      }
    }
  }

  &.-xs {
    height: 26px;
    padding: 0 $gutter;
    border-radius: 30px;
    font-size: 1.4rem;
  }

  &.-sm {
    height: 38px;
    padding: 0 (1.5 * $gutter);
    border-radius: 20px;
    font-size: 1.4rem;
  }

  &.-lg {
    height: 66px;
    padding: 0 (2 * $gutter);
    border-radius: 44px;
    font-size: 2rem;
  }

  &.-inline {
    display: inline-block;
    width: auto;
    vertical-align: top;
  }

  &.-shadow {
    box-shadow: $shadow;
  }

  &-container {
    width: 80%;
    margin: 0 auto;
    padding-top: 2 * $gutter;
    position: sticky;
    bottom: 2 * $gutter;
  }

}

a.btn {
  line-height: 42px;
  &.-sm {
    line-height: 36px;
  }
  &.-lg {
    line-height: 64px;
  }
}
