.good {

  &-inner {
    height: 200px;
    padding: (3.5 * $gutter) $gutter (4 * $gutter);
    background: $good-bg;
    box-shadow: 0 15px 8px rgba(0, 0, 0, .07);
    border-radius: 12px;
    position: relative;
    cursor: pointer;

    @include over-md() {
      height: 25.5rem;
    }

    &.-xs {
      padding: 2.1rem .4rem 1rem;
      height: auto;
      min-height: 8.3rem;
    }

    &_header {
      position: absolute;
      top: .3rem;
      left: .4rem;
      right: .4rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &-label {
    max-width: calc(100% - (2 * #{$gutter}));
    position: absolute;
    top: $gutter;
    left: $gutter;

    @include over-md() {
      top: 1.3rem;
      left: 1.3rem;
    }
  }

  .good-inner_header & {

    &-label {
      position: static;
      display: block;
    }
  }

  &-pic {
    height: 100%;
    overflow: hidden;
    display: flex;
  }

  .good-inner.-xs & {

    &-pic {
      margin: 0 auto;
      max-width: 6.3rem;
    }
  }

  &-img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    max-height: 160px;
    object-fit: contain;
  }

  &-description {
    min-height: 6.5rem;
    padding: ($gutter / 2) $gutter ($gutter / 2) (2 * $gutter + 2);
    background: $bg-primary;
    box-shadow: 0 4px 11px rgba(0, 0, 0, .18);
    border-radius: 9px;
    position: absolute;
    right: $gutter;
    bottom: -30px;
    left: $gutter;

    @include over-md() {
      min-height: 8.2rem;
      padding-top: 1rem;
      padding-bottom: .8rem;
      padding-left: 1.5rem;
      padding-right: 1.2rem;
    }

    &_line {
      @extend %row-width;

      &:not(:last-child) {
        margin-bottom: .1rem;
      }

      &.-no-wrap {
        flex-wrap: nowrap;
      }
    }
  }

  &-feature {
    @include col(10);
    flex-grow: 1;
    max-width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.2;
    color: $color-primary;
    white-space: nowrap;
    text-overflow: ellipsis;

    &_ico {
      @include col(2);
      padding-top: 0;
      padding-bottom: 0;
      font-size: 2rem;
      line-height: 0;
      color: $color-accent;
      text-align: right;
    }

    &_level {
      @include col(2);
      padding-top: 0;
      padding-bottom: 0;
      align-self: flex-end;
      margin-bottom: .6rem;
      max-width: 5.1rem;

      @include over-md() {
        min-width: 5.1rem;
        font-size: 3.1/2.1+rem;
      }
    }
  }

  &-price {
    @include col();
    padding-top: 0;
    padding-bottom: 0;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1;
    color: $color-accent;
    white-space: nowrap;

    @include over-md() {
      font-size: 2.9rem;
      letter-spacing: -.04/3.4+em;
    }

    @include over-lg() {
      font-size: 3.4rem;
    }
  }

  &-quality {
    @include col();
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1;
    color: $color-primary;
    text-align: right;

    @include over-md() {
      font-weight: 100;
    }
  }

  &-note {
    margin-top: -$gutter / 2;
    font-size: 1rem;
    line-height: 1;
    color: $color-note;
    text-align: right;

    @include over-md() {
      font-size: 1.2rem;
    }
  }

  &-quality-meter {
    position: absolute;
    top: $gutter;
    bottom: $gutter;
    left: $gutter;

    @include over-md() {
      left: $gutter/2;
    }
  }

  &-list {

    &_title {

      @include over-md() {
        display: none;
      }
    }
  }
}
.quality-meter {
  display: block;
  width: 5px;
  border-radius: 3px;
  overflow: hidden; }
  .quality-meter_span {
    display: block; }
    .quality-meter_span.-cut {
      background: #574bce; }
    .quality-meter_span.-color {
      background: #00bcff; }
    .quality-meter_span.-clarity {
      background: #ffd800; }
    .quality-meter_span.-carat {
      background: #ff007c; }
