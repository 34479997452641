.by-shape {
  text-align: center;

  &_wrapper {
    width: 250px;
    margin: 0 auto;
  }

  &_description {
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: -.15px;
    color: $color-description;
    padding-top: 5 * $gutter;
    max-width: 36rem;
    margin: 0 auto;

    @include over-md() {
      font-size: 2rem;
    }
  }

  &_footer {
    padding-top: 5 * $gutter;

    .btn {
      max-width: 20rem;
      margin: 0 auto;

      + .btn {
        margin-top: $gutter;
      }
    }
  }
}
