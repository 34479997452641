.clarity {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	&._param-description {
		padding-top: 3.5rem;
	}

	&.education__type {
		@include over-md() {
			padding-bottom: 5rem;
			margin-top: -7.2rem;
		}
	}

	&__item {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		min-width: 7.4rem;
		max-width: 7.4rem;

		@include over-md() {
			min-width: 18.2rem;
			max-width: 18.2rem;
		}

		@include under-md() {
			flex: 0 1 33.333%;
			min-width: 33.333%;
			max-width: 9.4rem;

			&:first-child {
				margin-bottom: 1.5rem;
			}
		}

		@include under-md() {
			&.poor,
			&.fair {
				border-top: 2px solid $ctrl-compare-color;
				padding-top: 2rem;
				margin-top: -2rem;

				&:before {
					position: absolute;
					content: '';
					left: 0;
					top: -2px;
					width: 45%;
					height: 12px;
					border-right: 2px solid $ctrl-compare-color;
					background-color: $bg-primary;
				}
			}

			&.fair {
				&:before {
					left: 55%;
					border-right: none;
					border-left: 2px solid $ctrl-compare-color;
				}
			}

			&.poor {
				&:after {
					position: absolute;
					content: 'Imperfections can be seen by the naked eye';
					font-size: 1rem;
					letter-spacing: -0.18px;
					color: #666666;
					width: 110%;
					top: -28px;
					left: 45%;
					text-align: center;
				}
			}
		}
	}

	&._param-description & {

		&__item {
			padding: .4rem 0;
			min-width: 7.5rem;
			max-width: none;

			&.fair {

				&:before {
					content: '';
					position: absolute;
					bottom: 100%;
					right: 100%;
					transform: translateX(50%);
					width: 4.4rem;
					height: 1rem;
					border: .1rem solid $ctrl-border;
					border-bottom: none;
				}
			}
		}
	}

	&._modal & {

		&__item {
			flex: 0 1 33.333%;
			min-width: 33.333%;
			max-width: 33.333%;

			&.poor {
				margin-bottom: 1rem;
			}
		}
	}

	&__image {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		width: 100%;
		padding-bottom: 3.7rem;

		img {
			max-width: 100%;
			box-shadow: 15px 2px 20px 0 rgba(0, 0, 0, 0.15);
			border-radius: 50%;
			@include under-md() {
				max-width: 55px;
			}
		}

		&:after {
			position: absolute;
			content: '';
			bottom: 10px;
			font-size: 1rem;
			font-weight: 100;
			letter-spacing: -0.33px;

			.poor & {
				content: 'I1 - I3';
			}

			.fair & {
				content: 'S1 - S12';
			}

			.good & {
				content: 'VS1 - VS2';
			}

			.very & {
				content: 'VVS1 - VVS2';
			}

			.excellent & {
				content: 'FL - IF';
			}

			@include over-md() {
				font-size: 2.4rem;
				bottom: 15px;
			}
		}

		@include over-md() {
			min-height: 18.6rem;
			padding-bottom: 6rem;
			align-items: flex-end;
		}
	}

	&._param-description & {

		&__image {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 0 1rem;
			padding: 0;
			width: 4.5rem;
			min-height: 0;

			&:after {
				position: static;
				margin-top: auto;
				font-weight: 300;
				font-size: 1.1rem;
				line-height: 8/7+em;
				color: #000;
				text-align: center;
				white-space: nowrap;
				padding-top: 1rem;
			}
		}
	}

	&__title {
		position: relative;
		margin-top: 1.4rem;
		font-weight: 300;
		font-size: 1rem;
		line-height: 12/10+em;
		text-align: center;
		width: 100%;

		@include over-md() {
			font-size: 2.4rem;

			.poor &,
			.fair & {
				border-bottom: 2px solid $ctrl-compare-color;
				padding-bottom: 2rem;

				&:before {
					position: absolute;
					content: '';
					left: 0;
					bottom: -2px;
					width: 50%;
					height: 12px;
					border-right: 2px solid $ctrl-compare-color;
					background-color: $bg-primary;

				}
			}

			&:before {
				.fair & {
					left: 50%;
					border-right: none;
					border-left: 2px solid $ctrl-compare-color;
				}
			}

			&:after {
				position: absolute;
				font-size: 1.6rem;
				letter-spacing: -0.18px;
				color: #666666;

				.poor & {
					content: 'Imperfections can be seen by the naked eye';
					width: 200%;
					bottom: -35px;
					left: 0;
				}
			}
		}

		&._accent {
			padding: .6rem .7rem .5rem;
			width: 8.9rem;
			border-radius: .3rem;
			background: $color-clarity;
			font-weight: 600;
			font-size: 1.2rem;
			line-height: 14/12+em;
			color: #fff;
			letter-spacing: -.13px;

			b {
				font-weight: 800;
				white-space: nowrap;
			}
		}
	}

	&._param-description & {

		&__title {
			margin: 1rem 0 0;
			padding: 0;
			border: none;
			font-weight: 400;
			font-size: 1.3rem;
			line-height: 8/7+em;
			color: #000;
			display: flex;
		  flex-direction: column;
		  justify-content: center;
		  align-items: center;

			&._accent {
				width: 9.4rem;
				color: #fff;
				padding: .6rem .7rem .5rem;
			}
		}

		&__item {

			&.poor {

				.clarity__title {
					position: static;

					&:after {
						bottom: calc(100% + 1.4rem);
						font-size: 1rem;
						line-height: 8/7+em;
						color: $dove-gray;
					}
				}
			}

			&.poor,
			&.fair {

				.clarity__title {

					&:before {
						display: none;
					}
				}

			}
		}
	}

	&._modal {

		@include under-md() {
			padding-top: 4rem;
			margin-left: -2rem;
			margin-right: -2rem;
		}

		.clarity__title {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}
}
