@import "src/styles/scss/basic/grids/variables";
@import "src/styles/scss/basic/variables";
@import "src/styles/scss/basic/mixins";

.container {
	position: relative;
	padding-top: 1.6rem;
	margin-bottom: 2.8rem;

	@include over-lg() {
		padding: 1.2rem 8rem 1.2rem 0;
		border-bottom: none;
	}
}

.list {
	list-style: none;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.item {

	&:not(:last-child) {
		margin-bottom: 2.5rem;
	}

	&:global {

		&._complex {
			order: 1;
			margin-top: 2.5rem;
		}
	}
}

.title {
	margin-bottom: .8rem;
	font-weight: 300;
	font-size: 1.4rem;
	line-height: 17/14+em;
	color: $product-param-title-color;
	text-transform: capitalize;
}

.range {
	z-index: 1;

	&Container {
		position: relative;
		height: 3.2rem;
		width: 100%;
		border-radius: 0;
	}
}

.track {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	border-radius: .5rem;
	height: 1rem;
	width: 100%;
	background: $progress-bg;
	pointer-events: auto;
}

.fill {
	position: absolute;
	height: 1rem;
	border-radius: .5rem;
}

.rangeContainer:global {

	&._green {

		:local {

			.fill {
				background-color: $progress-color;
			}
		}
	}

	&._color {

		:local {

			.fill {
				background-color: $color-color;
			}
		}
	}

	&._cut {

		:local {

			.fill {
				background-color: $color-cut;
			}
		}
	}

	&._carat {

		:local {

			.fill {
				background-color: $color-carat;
			}
		}
	}

	&._clarity {

		:local {

			.fill {
				background-color: $color-clarity;
			}
		}
	}
}

.slider {
	z-index: 1;
	top: 1.8rem;
	display: block;
	width: 1.6rem;
	pointer-events: auto;
	line-height: 0;

	svg {
		width: 100%;
		height: auto;
	}
}

.rangeContainer:global {

	&._green {

		:local {

			.slider {

				path {
					fill: $progress-color;
				}
			}
		}
	}

	&._color {

		:local {

			.slider {

				path {
					fill: $color-color;
				}
			}
		}
	}

	&._cut {

		:local {

			.slider {

				path {
					fill: $color-cut;
				}
			}
		}
	}

	&._carat {

		:local {

			.slider {

				path {
					fill: $color-carat;
				}
			}
		}
	}

	&._clarity {

		:local {

			.slider {

				path {
					fill: $color-clarity;
				}
			}
		}
	}
}

.inputs {
	display: flex;
	justify-content: space-between;
	margin-top: .4rem;

	&Wrapper {
		display: flex;
		align-items: center;
	}

	&:global {

		&._disabled {
			opacity: .5;
		}
	}
}

.label {
	margin-right: .4rem;
	font-size: 1.4rem;
	line-height: 17/14+em;
	color: #000;
}

.input {
	padding: 0 .4rem;
	width: 7.8rem;
	height: 2.4rem;
	border-radius: .4rem;
	border: .1rem solid $ctrl-border;
	outline: none;
	box-shadow: none;
	-webkit-appearance: none;
	font-weight: 400;
	font-size: 1.4rem;
	line-height: 17/14+em;
	letter-spacing: -.015em;
	color: $product-param-title-color;
}

.scale {
	list-style: none;
	margin-top: .4rem;
	display: flex;
	justify-content: space-around;

	&Item {
		flex: 1 1 100%;
		text-align: center;
		font-weight: 400;
		font-size: 1.4rem;
		line-height: 17/14+em;
		color: $product-param-title-color;
	}
}
