.modal {
  padding: $gutter;

  &-overlay {
    @include row();
    @include center();
    @include middle();
    background: $bg-primary;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
  }

  &-header {
    padding-bottom: 2 * $gutter;
    text-align: center;
  }

  &-title {
    padding: $gutter 0;
    color: $color-title-components;
    font-size: 1.8rem;
    font-weight: 300;
  }

  &-ico {
    line-height: 0;
    text-align: center;

    &.-success {
      font-size: 11.5rem;
      color: $color-accent;
    }
    &.-ribbon {
      font-size: 10rem;
      color: $color-carat;
    }
  }

}