@import 'shape';

.shapes {
  width: 250px;
  height: 0;
  margin: 0 auto;
  padding-top: 100%;
  position: relative;

  &-inner {
    border: 1px solid $color-border;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:before {
      content: '';
      display: block;
      width: 55%;
      height: 55%;
      border: 1px solid $color-border;
      border-radius: 50%;
      position: absolute;
      top: 22.5%;
      left: 22.5%;
      z-index: 10;
    }
  }

  &-center {
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    background: red;
  }

  .shape {
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: -10px;
    left: calc(50% - 15px);
    z-index: 20;

    &-img {
      width: 80%;
    }

    &:first-child {
      @include shapeRotate(0);
    }

    &:nth-child(2) {
      @include shapeRotate(36deg);
    }

    &:nth-child(3) {
      @include shapeRotate(72deg);
    }

    &:nth-child(4) {
      @include shapeRotate(108deg);
    }

    &:nth-child(5) {
      @include shapeRotate(144deg);
    }

    &:nth-child(6) {
      @include shapeRotate(180deg);
    }

    &:nth-child(7) {
      @include shapeRotate(216deg);
    }

    &:nth-child(8) {
      @include shapeRotate(252deg);
    }

    &:nth-child(9) {
      @include shapeRotate(288deg);
    }

    &:nth-child(10) {
      @include shapeRotate(324deg);
    }

    &.active {
      backface-visibility: hidden;

      &:before,
      &:after {
        bottom: -33px;
      	left: 70%;
      	border: solid transparent;
      	content: '';
      	height: 0;
      	width: 0;
      	position: absolute;
      	pointer-events: none;
        border-color: rgba(80, 227, 194, 0);
        transform: skewX(25deg) rotate(-232deg) scale(.75);
      }

      &:before {
      	border-bottom-color: $btn-bg;
      	border-width: 16px;
      	margin-left: -16px;
      }

      &:after {
      	border-bottom-color: $btn-bg;
      	border-width: 15px;
      	margin-left: -15px;
      }
    }

    .ctrl {
      height: 2.5rem;
    }
  }

}
