.product-details {
  @include row();
  @include flexWrap(nowrap);
  margin-top: $gutter;
  border-bottom: 1px solid $color-border;

  @include over-lg() {
    display: none;
  }

  &_overall{
    display: none;
  }

  &_item {
    @include col();
    text-align: center;
    white-space: nowrap;

    // hide bars for carat
    &:last-child {
      .level {
        display: none;
      }
    }

    &-description {
      opacity: 0;
      pointer-events: none;

      @include under-lg() {
        display: none;
      }
    }
  }

  &_icons {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 18px + ($gutter / 2);
    padding-bottom: $gutter / 2;

    .ico {
      display: inline-block;
      padding: 0 ($gutter / 4);
      vertical-align: middle;
      position: relative;

      &-Charge {
        font-size: 1.8rem;
        color: $color-accent;
      }

      &-DiamondSimple {
        position: relative;
        cursor: pointer;
        font-size: 1.4rem;

        &.-cut {
          color: $color-cut;
        }
        &.-color {
          color: $color-color;
        }
        &.-clarity {
          color: $color-clarity;
        }
        &.-carat {
          color: $color-carat;
        }

        &:after {
          content: '?';
          position: absolute;
          top: .3rem;
          left: 0;
          right: 0;
          font-family: $font;
          font-weight: 700;
          font-size: .7rem;
          line-height: 8/7+em;
          color: #fff;
          text-align: center;
        }
      }
    }

    &-text {
      display: inline-block;
      font-size: 1rem;
      font-weight: 700;
      line-height: 18px;
      color: $color-primary;
      vertical-align: middle;
    }
  }

  &_level {
    display: inline-flex;
    margin-right: .3rem;
    margin-bottom: .1rem;
  }

  &_descr {
    padding-top: $gutter / 2;
    font-size: 1.3rem;
    color: $color-primary;
    letter-spacing: -.1px;

    @include over-md() {
      font-size: 1.5rem;
    }

    &-title {
      font-weight: 300;
    }

    &-text {
      font-weight: 600;
      margin-left: 3px;
    }
  }
}
