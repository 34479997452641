@media only screen and (max-width: 1024px){
  .iiz__close {
    top: 10px;
    right: 25px;
  }
  .iiz__img{
    max-width: 70%;
    margin-left: 145px;
  }
}

@media only screen and (max-width: 600px){
  .iiz__close {
    top: -10px;
    right: 25px;
  }
  .iiz__img{
    max-width: 100%;
    margin-left: 0;
  }
}