.welcome {

  &-page {
    @include row();
    @include row-flex-column();
    @include end();
    @include middle();
    flex-grow: 1;
    padding-top: 2 * $gutter;
    padding-bottom: 7 * $gutter;
    color: $color-inverse;
    text-align: center;

    @include over-md() {
      justify-content: center;
      align-items: center;
      padding: 2rem;
    }
  }

  &-text {
    padding-bottom: 2 * $gutter;
    max-width: 75rem;
    font-size: 1.6rem;
    font-weight: 300;
    letter-spacing: -.18px;
    width: 90%;
    max-width: 350px;

    @include over-md() {
      flex: 1 1 auto;
      font-size: 3.2rem;
      max-height: 26.9rem;
      max-width: 660px;
    }
  }

  &-btns {
    width: 100%;
    max-width: 40rem;
    padding-top: 2 * $gutter;
    padding-bottom: 2 * $gutter;

    .btn {
      width: 80%;
      margin-right: auto;
      margin-left: auto;

      + .btn {
        margin-top: $gutter;
      }

      @include over-md() {
        margin: 0;
        width: auto;
      }
    }
  }

  &-link {
    font-size: 1.6rem;
    font-weight: 300;
    letter-spacing: -.18px;

    @include over-md() {
      font-size: 2.4rem;
    }
  }
}
