.level {
	font-size: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 2.1em;
	height: 1.4em;

	span {
		position: relative;
		width: .3em;
		border-radius: .2em;
		overflow: hidden;
		background: $color-border;

		&:not(:last-child) {
			margin-right: .3em;
		}

		$level-list-1: .6, .8, 1.1, 1.4;

		@for $i from 1 through 4 {

			&:nth-child(#{$i}) {
				height: 0;
				animation: level-animation-1-#{$i} .33s (.33 * ($i - 1))+s forwards;
			}

			@keyframes level-animation-1-#{$i} {

				0% {
					height: 0;
				}

				100% {
					height: nth($level-list-1, $i)+em;
				}
			}
		}
	}

	@include over-md() {

		&._lg {
			width: 5.6rem;
			height: 3.6rem;

			span {
				width: .8rem;
				border-radius: .4rem;

				&:not(:last-child) {
					margin-right: .8em;
				}

				$level-list-2: 1.6, 2.1, 2.8, 3.6;

				@for $i from 1 through 4 {

					&:nth-child(#{$i}) {
						height: 0;
						animation: level-animation-2-#{$i} .28s (.28 * ($i - 1))+s forwards;
					}

					@keyframes level-animation-2-#{$i} {

						0% {
							height: 0;
						}

						100% {
							height: nth($level-list-2, $i)+em;
						}
					}
				}
			}
		}
	}

	@for $i from 1 through 4 {

		&._#{$i} {

			span {

				&:nth-child(-n+#{$i}) {
					background-color: $color-accent;
				}
			}
		}
	}

	@for $i from 2 through 5 {

		&._#{$i} {
			span {
				@for $j from 1 through 4 {
					&:nth-child(n+#{$j}) {
						animation-delay: (.28 * $j)+($i - 1.28)+s;
					}
				}
			}
		}
	}

	@for $i from 1 through 3 {

		&.animate#{$i} {
			span {
				@for $j from 1 through 4 {
					&:nth-child(n+#{$j}) {
						animation-delay: (.28 * $j)+($i - .28)+s;
					}
				}
			}
		}
	}
}
