@mixin volume($name, $W, $w, $H, $h, $n: 4) {
	box-sizing: content-box;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: $W/10+rem;
	height: $H/10+rem;

	span {
		margin: 0 !important;
		width: $w / 10 + rem;
		border-radius: $h / 2 / 10 + rem;
		background: $color-border;

		@for $i from 0 to $n {
			$test: 12;

			&:nth-child(#{$i + 1}) {
				height: ($h + (($H - $h) / ($n - 1)) * $i) / 10 + rem;
				animation: volume-animation .33s (.33 * $i)+s forwards;
				transform-origin: bottom;
				opacity: 0;
			}

			@keyframes volume-animation {
				0% {
					transform: scaleY(0);
					opacity: 1;
				}
				
				100% {
					transform: scaleY(1);
					opacity: 1;
				}
			}
		}

		@include over-lg() {

			@for $i from 0 to $n {

				&:nth-child(#{$i + 1}) {
					animation-duration: .28s;
					animation-delay: (.28 * $i)+s;
				}
			}
		}
	}

	@for $i from 1 through 4 {

		&._#{$i} {

			span {

				&:nth-child(-n+#{$i}) {
					background-color: $color-accent;
				}
			}
		}
	}
}