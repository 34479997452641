@keyframes circle-timer {
  to {
    stroke-dashoffset: 0;
  }
}

.diamond-education-tabs-container {
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
  border-bottom: 1px solid #c1c1c1;

  @include under-lg() {
    padding-top: 0;
    flex-direction: column;
  }

  .navigation-container {
    align-self: center;

    @include under-lg() {
      display: flex;
      margin-bottom: 20px;
    }

    @include under-sm() {
      max-width: 370px;
    }

    .navigation-tab {
      display: flex;
      align-items: flex-start;
      margin: 20px 0;
      padding: 12px 16px;
      color: #c9c9c9;
      border: 1px dashed lightgray;
      border-radius: 10px;
      font-size: 2rem;
      cursor: pointer;

      @include under-lg() {
        padding: 8px 12px;
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }
      }

      @include under-md() {
        width: 185px;
      }

      @include under-sm() {
        align-items: center;
        width: 110px;
        margin: 10px 2px;
        padding: 4px 8px;
      }

      span {
        @include under-lg() {
          display: none;
        }
      }

      .icon-container {
        position: relative;
        align-self: center;
        display: grid;
        place-items: center;
        margin-right: 15px;

        @include under-sm() {
          margin-right: 5px;
        }

        .icon-circle {
          stroke: #c9c9c9;
          stroke-dasharray: 200;
          stroke-dashoffset: 200;

          @include under-md() {
            transform: scale(0.9);
          }
        }

        .diamond-icon {
          position: absolute;
          width: 25px;
          height: 25px;
          fill: #c9c9c9;

          @include under-md() {
            width: 20px;
            height: 20px;
          }
        }
      }

      .tab-name {
        flex: 1;

        &--title {
          font-size: 2.5rem;

          @include under-md() {
            font-size: 1.5rem;
          }

          @include under-sm() {
            font-size: 1.2rem;
          }
        }

        &--subtitle {
          font-size: 1.6rem;

          @include under-md() {
            font-size: 1.2rem;
          }

          @include under-sm() {
            display: none;
          }
        }
      }

      &.active {
        background-color: #f6f9fa;
        border: none;
        color: initial;

        span {
          color: black;
        }

        .icon-container {
          .icon-circle {
            animation: circle-timer 11s linear;
          }

          &.cut {
            .icon-circle {
              stroke: $color-cut;
            }

            .diamond-icon {
              fill: $color-cut;
            }
          }
          &.color {
            .icon-circle {
              stroke: $color-color;
            }
            .diamond-icon {
              fill: $color-color;
            }
          }
          &.clarity {
            .icon-circle {
              stroke: $color-clarity;
            }
            .diamond-icon {
              fill: $color-clarity;
            }
          }
          &.carat {
            .icon-circle {
              stroke: $color-carat;
            }
            .diamond-icon {
              fill: $color-carat;
            }
          }
        }

        .tab-name--subtitle {
          color: $color-description;
        }
      }
    }
  }

  .details-container {
    flex: 1;

    @include over-lg() {
      min-height: 575px;
    }

    .tab-component-container {
      margin: 50px 0 25px;

      .cut {
        &__item {
          justify-content: space-between;
        }
        &__image img {
          @include under-md() {
            max-width: 60px;
          }
        }
      }

      .color {
        width: fit-content;
        margin: auto;

        @include under-md() {
          width: 285px;
        }

        &__letters {
          b {
            top: 32px;
            font-size: 1.8rem;

            @include under-md() {
              top: -20px;
              font-size: 1.2rem;
            }
          }
        }
      }

      .clarity {
        .clarity__item {
          &.poor::after {
            @include under-md() {
              top: 0;
            }
          }

          &.poor,
          &.fair {
            border-top: 2px solid $ctrl-compare-color;
            padding-top: 2rem;
            margin-top: -2rem;

            &:before {
              position: absolute;
              content: "";
              left: 0;
              top: -2px;
              width: 45%;
              height: 12px;
              border-right: 2px solid $ctrl-compare-color;
              background-color: $bg-primary;
            }
          }

          &.fair {
            &:before {
              left: 55%;
              border-right: none;
              border-left: 2px solid $ctrl-compare-color;
            }
          }

          &.poor {
            &:after {
              position: absolute;
              content: "Imperfections can be seen by the naked eye";
              letter-spacing: -0.18px;
              color: #666666;
              width: 110%;
              top: -35px;
              left: 45%;
              text-align: center;
              font-size: 1.3rem;
            }
          }
        }

        &__title {
          border: none;
          &::before,
          &::after {
            content: "";
            border: none;
            background-color: transparent;
          }
        }
      }
    }

    .subtitle {
      font-size: 2rem;
      margin: 20px auto;
      width: fit-content;
      color: $color-description;
    }
    .text {
      @include over-lg() {
        width: 400px;
      }

      font-size: 2rem;
      margin: 30px auto 0;
      text-align: center;
      color: $color-description;
    }
  }
}
