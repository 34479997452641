.account {

	&__section {
		padding: 1.6rem 0 3.6rem;

		&._gray {
			margin: 0 -2rem;
			padding-left: 2rem;
			padding-right: 2rem;
			background: $bg-secondary;

			@include over-lg() {
				margin: 0 -3rem -3rem;
				padding: 3rem 3rem 7rem;

				.account {

					&__h2 {
						border-bottom: none;
						margin-bottom: 3.1rem;
					}
				}
			}
		}
	}

	&__h2 {
		margin-bottom: 2.8rem;
		@extend .title-1;
		text-transform: uppercase;

		@include over-lg() {
			margin-bottom: 1.2rem;
		}
	}

	&__h3 {
		margin-bottom: 1.5rem;
		font-weight: 600;
		font-size: 1.6rem;
		line-height: 19/16+em;
		text-transform: uppercase;

		@include over-lg() {
			margin: 0;
			padding-top: 2.6rem;
			padding-bottom: 2.6rem;
			border-top: .1rem solid $color-note;
			border-bottom: .1rem solid $color-note;
		}
	}

	&__thead {
		display: flex;
		align-items: center;
		height: 4rem;
		background: rgba($btn-bg-hover-secondary,.17);


		@include under-lg() {
			display: none;
		}
	}

	&__th {
		flex: 0 1 20%;
		font-weight: 600;
		font-size: 1.4rem;
		line-height: 17/14+em;
		color: $ctrl-color;
		text-transform: uppercase;
		text-align: center;

		&:first-child {
			text-align: left;
		}
	}

	&__collapse {
		padding: 1.4rem 0 0;
		border-top: .1rem solid $ctrl-color-secondary;

		@include over-lg() {
			display: flex;
			align-items: center;
			padding: 0;
			height: 11.1rem;
			border-color: $color-note;

			&._first {
				border-top: none;
			}
		}

		&-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 1rem;

			@include over-lg() {
				margin-bottom: 0;
			}

			&:after {
				content: '';
				width: 1.5em;
				height: .8rem;
				background: url("/img/select-triangle.svg") no-repeat left top;

				@include over-lg() {
					display: none;
				}
			}
		}

		&._opened {

			.account__collapse {

				&-header {

					&:after {
						transform: rotate(180deg);
					}
				}
			}

			.collapse__content {
				@include over-lg() {
					display: flex;
				}
			}
		}

		&-btn {
			@extend %button;
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 15.5rem;
			height: 2.3rem;
			padding: 0 1rem;
			border-radius: 1.2rem;
			background: $color-accent;
			@extend .text-2;
			font-weight: 700;
			color: #fff;
			text-transform: uppercase;
			text-align: center;
			white-space: nowrap;

			@include over-lg() {
				cursor: default !important;
			}
		}

		.collapse {

			@include over-lg() {

				&__content {
					display: flex;
				}

				&__btn {
					flex: 0 1 20%;
					cursor: default;
				}

				&__content {
					flex: 0 1 60%;
					align-items: center;

					.account {

						&__param {
							flex: 0 1 33.333%;
						}
					}
				}
			}
		}

		&-visible {

			@include over-lg() {
				flex: 0 1 20%;
				display: flex;
				justify-content: center;
			}
		}
	}

	&__param {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 2.4rem;

		@include over-lg() {
			flex-direction: row;
			justify-content: center;
			margin-bottom: 0;
		}

		&-key {
			margin-bottom: .4rem;
			@extend .text-2;
			font-weight: 600;
			color: $ctrl-color;
			text-transform: uppercase;

			@include over-lg() {
				display: none;
			}
		}

		&-value {
			@extend .text-2;
		}
	}

	&__status {
		@extend %button;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 15.5rem;
		height: 2.3rem;
		padding: 0 1rem;
		border-radius: 1.2rem;
		background: #fff;
		@extend .text-2;
		font-weight: 700;
		color: #000;
		text-transform: uppercase;
		text-align: center;
		white-space: nowrap;

		&._pending {
			background: #ff6634;
			color: #fff;
		}
	}

	&__info {

		@include over-lg() {
			display: flex;
			align-items: flex-start;
		}

		&-item {

			@include over-lg() {
				flex: 0 1 25%;

				.account {

					&__h3 {
						margin-bottom: 1.1rem;
						padding: 0;
						border: none;
					}
				}
			}

			&:not(:last-child) {
				margin-bottom: 6rem;
			}

			p {
				font-weight: 400;
				font-size: 1.6rem;
				line-height: 1.5;
				color: #000;
			}

			span {

				&._d-b {
					display: block;
				}
			}

			button {
				@extend %button;
				font-weight: 400;
				font-size: 1.6rem;
				line-height: 1.5;
				color: #000;
				text-decoration: underline;
			}
		}
	}
}
