.product-info {
  padding-top: $gutter;
  padding-bottom: $gutter;

  &_row {
    @include row();
    @include middle();
    @include flexWrap(nowrap);
  }

  &._product-page {
    @include over-lg() {
      padding: 0;
    }

    .product-info {
      &_row {
        &._under-lg {
          @include over-lg() {
            display: none;
          }
        }
      }
    }
  }

  &_col {
    flex: 1;
  }

  &_shape {
    @include row();
    @include middle();
    justify-content: space-between;

    .diamond-compare-icons {
      display: flex;
      align-items: center;
    }

    &-label {
      flex-shrink: 0;
      .ico {
        padding-left: $gutter / 2;
        vertical-align: middle;
      }
    }

    .ctrl.-lg svg {
      // custom small screen view
      @media screen and (max-width: 450px) {
        width: 2.6rem;
      }
    }
  }

  &_compare {
    margin-left: $gutter;

    &_btn {
      width: auto;
      margin-left: $gutter;
      min-width: 15rem;

      &.btn {
        // custom small screen view
        @media screen and (max-width: 450px) {
          font-size: 1.1rem;
          min-width: 1rem;
        }

        @include over-md() {
          font-size: 1.8rem;
          height: 44px;
          min-width: 18rem;
        }
      }

      &.-active.btn {
        border-width: 0;
        background-color: $btn-bg;
        color: #fff;
      }
    }
  }

  &._product-page & {
    &_compare,
    &_compare_btn {
      @include over-lg() {
        display: none;
      }
    }
  }

  &_accent {
    font-size: 1.8rem;
    font-weight: 700;
    color: $color-primary;
  }

  &_ico {
    @include row();
    @include middle();
    @include end();
    height: 100%;
    flex-shrink: 0;

    &-img {
      width: 50px;
    }
  }

  &_price {
    font-size: 2.4rem;
    font-weight: 700;
    color: $color-accent;
  }
}
