.success {
	position: fixed;
	z-index: 1010;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: $gutter;
	background: #fff;

	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 2 * $gutter;
		text-align: center;
	}

	&__title {
		padding: $gutter 0;
		color: $color-title-components;
		font-size: 1.8rem;
		font-weight: 300;
	}

	&__ico {
		line-height: 0;
		text-align: center;

		&._success {
			width: 11.5rem;

			svg {
				width: 100%;
				height: auto;
			}
		}

		&._ribbon {
			font-size: 10rem;
			color: $color-carat;
		}
	}

}