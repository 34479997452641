.checkbox {
	display: flex;
	align-items: center;

	label {
		flex: 1 1 auto;
		display: flex;
		align-items: center;
		cursor: pointer;

		i {
			position: relative;
			display: block;
			width: 2.6rem;
			height: 2.5rem;
			border-radius: .5rem;
			border: .2rem solid $color-note;
		}

		span {
			flex: 1 1 auto;
			margin-left: 1.2rem;
		}
	}

	input:checked {

		& + label {
			position: relative;

			i:after {
				content: '';
				position: absolute;
				top: 50%;
				left: .1rem;
				width: 2rem;
				height: 1.5rem;
				transform: translateY(-50%);
				background: url("/img/checked.svg") no-repeat left top;
			}
		}
	}
}

.absolute-checkbox {
	position: absolute;
	top: 1rem;
	right: 1.5rem;

	@include over-md() {
		top: 1.3rem;
	}
}