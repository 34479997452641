.ring-diamond-selected-container {
  display: flex;
  width: 260px;
  min-height: 78px;
  box-shadow: 0 15px 8px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 15px 8px rgba(0, 0, 0, 0.07);
  &:empty {
    box-shadow: none;
  }
}

.ring-diamond-selected-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  .ring-diamond-selected-button-label {
    color: white;
    background-color: black;
    border-radius: 10px;
    line-height: 15px;
    font-size: 8pt;
    font-weight: 400;
    text-align: center;
    &:first-child {
      width: 40%;
      margin-right: 4px;
    }
    &:nth-child(2) {
      width: 60%;
      margin-right: 10px;
      cursor: pointer;
    }
    img {
      width: 10px;
      height: 10px;
      margin-right: 6px;
    }
  }
}

.ring-diamond-selected-img-container {
  margin-left: 6px;
  align-self: center;
  img {
    width: 65px;
    height: 65px;
    margin-left: 1px;
    border: 3px solid rgba(196, 196, 196, 0.6);
    border-radius: 10px;
    object-fit: cover;
  }
}

.ring-diamond-selected-info-container {
  margin-left: 10px;
}

.ring-diamond-selected-details-containers {
  flex-direction: row;
  margin-top: 2px;
}

.ring-diamond-selected-details-subcontainer {
  flex: 1;
}

.ring-diamond-selected-detail {
  color: black;
  font-size: 12pt;
  font-weight: 700;
}

.ring-diamond-selected-price {
  color: #50e3c2;
  font-size: 12pt;
  font-weight: 700;
}
