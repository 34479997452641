.error {
  color: red;

  label {
    color: red;
  }

  span:last-child {
    font-size: 1.5rem;

    @media only screen and (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  input {
    border-color: red;
    border-width: 0.2rem;
  }
}

.required {
  &::after {
    content: "*";
    color: red;
    margin-left: 2px;
  }
}
