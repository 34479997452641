.color {

  &-cut {
    color: $color-cut;
  }

  &-color {
    color: $color-color;
  }

  &-clarity {
    color: $color-clarity;
  }

  &-carat {
    color: $color-carat;
  }

  &-accent {
    color: $color-accent;
  }

  &-primary {
    color: $color-primary;
  }

  &-secondary {
    color: $color-secondary;
  }

}
