.couple {
  .affirm-payment-container {
    border: 1px solid #b5b5b5;
    border-radius: 4px;
    padding: 20px;
    margin: 32px 0;
    min-height: 50px;
    font-size: 1.8rem;
    color: #565353;

    .btn {
      margin: 0 0 0 auto;

      &:disabled {
        border: none;
      }
    }

    .affirm-logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 200px;

      img {
        width: 100px;
      }
      span {
        font-size: 1.6rem;
        position: relative;
        top: 5px;
      }
    }
    p {
      margin: 20px 0 50px;
    }
  }

  @include over-md() {
    display: flex;
    align-items: stretch;
    max-width: 100%;
  }

  &__item {
    @include over-md() {
      flex: 0 1 50%;
      min-width: 0;

      &:first-child {
        padding-right: 2rem;
      }

      &:last-child {
        padding-left: 2rem;
      }
    }

    .payment-radio-button-container {
      margin: 15px 0;

      .payment-radio-button-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: fit-content;
        color: $color-title;

        input[type="radio"] {
          margin-right: 10px;
        }
        label {
          font-size: 2rem;
          cursor: pointer;

          span {
            margin-left: 10px;
          }
        }
      }
    }
  }

  &._step-1 & {
    @include under-md() {
      &__item._step-2 {
        display: none;
      }
    }
  }

  &._step-2 & {
    padding-top: 22px;

    @include under-md() {
      &__item._step-1 {
        display: none;
      }
    }
  }

  &__wrapper {
    @include over-md() {
      margin: 0 auto;
      width: 100%;
      max-width: 44rem;
    }
  }

  &__submit {
    @include over-md() {
      display: flex;
      justify-content: flex-end;

      .btn {
        max-width: 30rem;
        min-width: 30rem;
      }
    }
  }

  &__review {
    margin: 0 auto;
    max-width: 52.2rem;
  }
}
