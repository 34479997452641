* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font;

  &:before,
  &:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}
