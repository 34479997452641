%row-width {
  @include row();
  width: calc(100% + (2 * #{$gutter}));
  margin-right: -$gutter;
  margin-left: -$gutter;
}

%row-height {
  @include row();
  margin-top: -$gutter;
  margin-bottom: -$gutter;
}
