.education-level {
	@include volume("education-level", 21, 3, 14, 6);

	@include over-lg() {
		@include volume("education-level-over-lg", 56, 8, 36, 16);
	}

	&._param-description {
		@include volume("education-level--param-description", 21, 3, 14, 6);
	}

	@for $i from 2 through 5 {

		&._#{$i} {
			span {
				@for $j from 1 through 4 {
					&:nth-child(n+#{$j}) {
						animation-delay: (.28 * $j)+($i - 1.28)+s;
					}
				}
			}
		}
	}
}
