.product-page {
	position: relative;
	height: 100%;
	max-width:100%;
	background-color: #fff;

	@include over-lg() {
		padding-bottom: 11.7rem;
	}

	&__loader {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		font-size: 2rem;
		min-height: 378px;

		img {
			max-width: 200px;
			margin-top: 2rem;
		}
	}

	&__diamond {

		@include over-lg() {
			position: relative;
			flex: 1 1 auto;
			max-width: 63.4rem;
			width: 49%;
		}

	}

	&__slider {
		display: flex;
		justify-content: center;
		overflow: hidden;

		@include over-lg() {
			position: relative;
			top: 0;
			margin: 0;
			width: auto;
			height: 38.1rem;
		}
	}

	&__bars {

		@include over-lg() {
			position: relative;
			z-index: 110;
			margin: -4rem 1.6rem 2.8rem;
			width: auto;
		}
	}

	&__shape-select {

		@include over-lg() {
			position: absolute;
			z-index: 109;
			top: 1.2rem;
			left: 1.6rem;
			padding: 0 !important;

			.shapes-dropdown {
				margin-top: .5rem;
				width: auto;
				min-width: 100%;
			}
		}
	}

	&__up {

		@include over-lg() {
			display: flex;
			flex-wrap: wrap;
			border-bottom: 1px solid #c1c1c1;
		}
	}

	&__tabs {

		@include over-lg() {
			flex: 1 1 auto;
			max-width: 63.4rem;
			width: 100%;
			order: 1;
		}
	}

	&__params {

		@include over-lg() {
			flex: 1 1 405px;
			margin-left: 8.5rem;
		}

		.notice-instructions{
			position: absolute;
			top: 0;
			font-size: 2rem;
			font-weight: 300;
			color: #000544;
			width: fit-content;

			.good-deal {
				position: relative;
				z-index: 0;

				&::after {
					content: '';
					position: absolute;
					left: 0;
					bottom: 0;
					right: 0;
					height: 50%;
					background-color: $color--primary;
					opacity: 0.9;
					z-index: -1;
				}
			}
		}
	}

	&__gia {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 1.3rem 1rem 1.3rem 1.5rem;

		@include under-lg() {
			display: none;
		}

		&-image {
			margin-bottom: 1.8rem;
			width: 10.6rem;
			line-height: 0;

			img {
				width: 100%;
			}
		}

		&-btn {
			min-width: 15.4rem;
		}
	}

	&__fixed-footer {

		@include over-lg() {
			position: fixed;
			z-index: 109;
			bottom: 0;
			left: 0;
			right: 0;
			padding-top: 1rem;
			padding-bottom: 1rem;
			min-height: 12.7rem;
			border-top: .1rem solid $btn-border;
			background: #fff;
		}

		&-container {

			@include over-lg() {
				display: flex;
				align-items: center;
			}
		}

		&-content {
			margin-right: auto;

			@include under-lg() {
				display: none;
			}
		}

		&-btn {
			position: fixed;
			bottom: 9.5rem;
			left: 50%;
			transform: translateX(-50%);
			width: 19.4rem;

			&:disabled {
				border: none;
				box-shadow: none;
			}

			@include under-lg() {
				&:not(.compare) {
					opacity: 0;
					&.active {
						opacity: 1;
						transition: opacity .3s ease-in;
					}
				}
			}

			@include over-lg() {
				min-width: 30rem;

				&:not(.compare) {
					position: static;
					transform: none;
				}
			}
		}

		&-item {
			display: none;

			&.hide {
				display: none;
			}

			@include over-lg() {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 0 2.6rem;
				min-width: 365px;
			}
		}

		&-price {
			font-size: 4.2rem;
			line-height: 52/42+em;
			color: $progress-color;

			&.over-limit {
				font-weight: 700;
			}
		}

		&-description {
			font-weight: 100;
			font-size: 1.8rem;
			line-height: 22/18+em;
			color: #000;
		}
	}

	.diamond-history-module {
		display: flex;
		flex-flow: wrap;
		margin-top: 2rem;
		justify-content: center;

		.title {
			flex-basis: 100%;
			color: $product-param-title-color;
	    font-size: 2rem;
	    font-weight: 300;
			margin-bottom: 1rem;
			text-align: center;
		}

		button {
			background: transparent;
			border: none;

			&:disabled {
				cursor: not-allowed;
				opacity: 0.5;
			}

			path {
				fill: #9B9B9B;
			}

			&:not(:disabled) {
				&:hover path {
					fill: $btn-bg;
				}
			}
		}

		svg {
			height: 2rem;
		}

		.diamond-history_icon {
			margin-left: 1.5rem;
			margin-right: 1.5rem;
		}

		.previous svg {
			transform: rotate(180deg);
		}
	}

	&__info {
		margin-bottom: 2.8rem;
		border-bottom: 2px solid $color-border;

		@include over-lg() {
			order: 1;
			flex: 1 1 auto;
			display: flex;
			align-items: center;
			margin-bottom: .5rem;
			border: none;
			width: 100%;
		}

		&-text {

			@include over-lg() {
				flex: 1 1 100%;
			}
		}

		p {
			font-size: 2rem;
			line-height: 1.5;

			&:not(:last-child) {
				margin-bottom: .5rem;
			}
		}
	}
}

.custom-tour {
	font-size: 1.75rem;
	padding-right: 4rem;

	button:focus {
		outline: none;
	}

	.btn {
		min-width: 1rem;
		line-height: 38px;
	}
}
