@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?fdfp51') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?fdfp51') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?fdfp51##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.ico {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-Check {
  &:before {
    content: $ico-Check; 
  }
}
.ico-Check2 {
  &:before {
    content: $ico-Check2; 
  }
}
.ico-Menu {
  &:before {
    content: $ico-Menu; 
  }
}
.ico-Minus {
  &:before {
    content: $ico-Minus; 
  }
}
.ico-Plus {
  &:before {
    content: $ico-Plus; 
  }
}
.ico-ArrowLeft {
  &:before {
    content: $ico-ArrowLeft; 
  }
}
.ico-ArrowTop {
  &:before {
    content: $ico-ArrowTop; 
  }
}
.ico-ArrowRight {
  &:before {
    content: $ico-ArrowRight; 
  }
}
.ico-ArrowBottom {
  &:before {
    content: $ico-ArrowBottom; 
  }
}
.ico-Cross {
  &:before {
    content: $ico-Cross; 
  }
}
.ico-AcademicCap {
  &:before {
    content: $ico-AcademicCap; 
  }
}
.ico-ArrowBack {
  &:before {
    content: $ico-ArrowBack; 
  }
}
.ico-Bag {
  &:before {
    content: $ico-Bag; 
  }
}
.ico-BagDiamond {
  &:before {
    content: $ico-BagDiamond; 
  }
}
.ico-Charge {
  &:before {
    content: $ico-Charge; 
  }
}
.ico-Diamond {
  &:before {
    content: $ico-Diamond; 
  }
}
.ico-DiamondQuestion {
  &:before {
    content: $ico-DiamondQuestion; 
  }
}
.ico-Diamonds {
  &:before {
    content: $ico-Diamonds; 
  }
}
.ico-DiamondSimple {
  &:before {
    content: $ico-DiamondSimple; 
  }
}
.ico-Fire {
  &:before {
    content: $ico-Fire; 
  }
}
.ico-HandShake {
  &:before {
    content: $ico-HandShake; 
  }
}
.ico-Leaf {
  &:before {
    content: $ico-Leaf; 
  }
}
.ico-MicroChip {
  &:before {
    content: $ico-MicroChip; 
  }
}
.ico-Ribbon {
  &:before {
    content: $ico-Ribbon; 
  }
}
.ico-Ring {
  &:before {
    content: $ico-Ring; 
  }
}
.ico-Ring2 {
  &:before {
    content: $ico-Ring2; 
  }
}

@media (min-width:320px) and (max-width:992px)  {
  #chat-svg {
    height: 25px !important;
    width: 25px !important;
  }
}



#customerly-launcher {
    display: none;
}

#chat-svg {
  height: 50px;
  width: 50px;
}