.table-wrapper {

	&:not(:last-child) {
		margin-bottom: 1.7rem;

		@include over-lg() {
			margin: 0 12rem 0 0;
		}
	}

	@include over-lg() {
		flex: 1 1 calc(33.333% - 24rem/3);
		min-width: calc(33.333% - 24rem/3);
	}
}

.table-title {
	font-weight: 400;
	font-size: 1.4rem;
	line-height: 17/14+em;
	color: $color-description;

	@include over-lg() {
		margin-bottom: 2.4rem;
		font-weight: 300;
		font-size: 2.4rem;
		line-height: 29/24+em;
		color: #000;
	}
}

.table {
	width: 100%;

	td {
		font-weight: 300;
		font-size: 1.4rem;
		line-height: 17/14+em;
		color: #000;

		@include over-lg() {
			font-size: 1.6rem;
			line-height: 20/16+em;
		}

		&:not(:last-child) {
			padding-right: 1.5rem;
		}

		&._c-gray {
			color: $color-description;
		}

		&._ta-r {
			text-align: right;
		}
	}
}